html {
	font-size: 16px;
	
	@media (min-width: 2100px) {
		font-size: 20px;
	}

	@media (min-width: 2300px) {
		font-size: 18px;
	}

	@media (min-width: 2500px) {
		font-size: 20px;
	}

	@media (min-width: 2700px) {
		font-size: 22px;
	}

	@media (min-width: 2900px) {
		font-size: 24px;
	}

	@media (min-width: 3100px) {
		font-size: 27px;
	}

	@media (min-width: 3300px) {
		font-size: 30px;
	}


	@media (max-width: 1575px) {
		font-size: 14px;
	}

	@media (max-width: 1380px) {
		font-size: 12px;
	}

	@media (max-width: 1190px) {
		font-size: 10px;
	}

	@media (max-width: 990px) {
		font-size: 8px;
	}

	@media (max-width: 800px) {
		font-size: 7.5px;
	}

	@media (max-width: 767px) {
		font-size: 16px;
	}
}

body {
	background:  url("../img/images/main-bg.jpg"), #E5E5E5;
	color: #0C284E;
	font-family: "Futura PT";
	position: relative;
	font-size: rem(21);
	line-height: rem(34);
}

a {
	color: inherit;
	text-decoration: none;
}


.main {
	background: #fff;
	display: flex;
	flex-flow: column;

	@media (max-width: 767px) {
		padding-top: rem(65);
	}
}

.wrapper {
	max-width: rem(1555);
	width: 100%;
	margin: 0 auto;
	box-shadow: rem(0) rem(4) rem(17) rgba(255, 255, 255, 0.25);
	overflow: hidden;
	background-color: #fff;
	position: relative;
}

.container {
	width: 100%;
	position: relative;
	margin: 0 auto;
	padding: 0 rem(55);

	@media (max-width: 767px) {
		padding: 0 rem(20);
	}
}

.container-2 {
	width: 100%;
	position: relative;
	margin: 0 auto;
	padding: 0 rem(128);

	@media (max-width: 767px) {
		padding: 0 rem(20);
	}
}

.btn {
	background: #215AA7;
	border-radius: rem(1000);
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: bold;
	font-size: rem(16);
	line-height: rem(19);
	text-transform: uppercase;
	color: #FFFFFF;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include transition;

	&:hover {
		text-decoration: none;
		background-color: #153E75  !important;
	}
}

.mob-visible {
	@media (min-width: 768px) {
		display: none !important;
	}
}

.desk-visible {
	@media (max-width: 767px) {
		display: none !important;
	}
}

.center-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
}


.os-scrollbar-vertical {
	margin-right: rem(12);
	background: rgba(255, 255, 255, 0.5) !important;
	border: rem(1) solid #DFEAF8  !important;
	box-sizing: border-box !important;
	width: rem(10) !important;

	@media (max-width: 767px) {
		margin-right: 0;
	}
}

.os-theme-dark.os-host-transition>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
	background: #D8EEFE !important;
	border-radius: rem(50) !important;
	width: rem(4) !important;
}

