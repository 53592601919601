.hero-robot {
	height: rem(712);
	width: 100%;
	position: relative;
	background: url("../img/images/main-img-1.jpg") no-repeat 50% 100% / cover;
	
	@media (max-width: 767px) {
		height: rem(400); 
	}

	@media (max-width: 480px) {
		height: rem(257); 
	}

	.btn2 {
		max-width: rem(217);
		width: 100%;
	}
}

.dialog-robot {
	list-style: none;
	width: rem(450);
	display: inline-block;
	position: absolute;
	bottom: rem(180);
	left: rem(193);
	display: flex;
	flex-flow: column;
	padding-right: rem(50);
	overflow: hidden;

	@media (max-width: 767px) {
		width: rem(220);
		bottom: 44%;
		left: 15%;
	}

	@media (max-width: 480px) {
		left: rem(20);
	}

	&.step-1 {
		.dialog-robot__item-first {
			opacity: 1;
			position: relative;
		}
	}

	&.step-2 {
		.dialog-robot__item-first:after {
			display: none;
		}
		.dialog-robot__item-second {
			opacity: 1;
			position: relative;
		}
	}

	&.step-3 {

		.dialog-robot__item-second {
			opacity: 0;
			position: absolute;
		}
		.dialog-robot__item-third {
			opacity: 1;
			position: relative;
		}
	}

	&__item {
		background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 85%, rgba(255, 255, 255, 0.9) 85%, #fff 98%);
		border-radius: rem(100);
		min-height: rem(93);
		min-width: rem(93);
		margin-bottom: rem(17);
		display: inline-flex;
		align-items: center;
		padding-left: rem(39);
		padding-right: rem(25);
		width: 100%;
		position: relative;
		opacity: 0;
		position: absolute;
		transition: all .2s ease;

		@media (max-width: 767px) {
			min-height: rem(38);
			min-width: rem(38);
			padding-left: rem(12);
			padding-right: rem(15);
			margin-bottom: rem(8);
		}

		&:after {
			content: "";
			display: block;
			border: rem(18) solid transparent;	
			border-left: rem(30) solid #fff;
			position: absolute;
			top: 50%;
			right: rem(-44);
			transform: translate(0, -50%);
			z-index: 2;

			@media (max-width: 767px) {
				display: none;
			}
		}

		&--ellipsis {
			padding: 0;
			height: rem(93);
			justify-content: center;
			width: auto;
			align-self: flex-start;
			transition: none;

			@media (max-width: 767px) {
				height: rem(38);
			}

			&:after {
				display: none;
			}
		}
	}

	&__hello {
		font-family: "Futura PT";
		font-style: italic;
		font-weight: bold;
		font-size: rem(24);
		line-height: rem(31);
		color: #215AA7;

		@media (max-width: 767px) {
			font-size: rem(12);
			line-height: rem(15);
		}
	}

	&__ellipsis {
		font-family: Futura PT;
		font-style: italic;
		font-weight: bold;
		font-size: rem(24);
		color: #215AA7;

		@media (max-width: 767px) {
			font-size: rem(10);
		}

		span {
			position: relative;
			top: rem(-5);
			animation: dialog-item-ellipsis 1s ease infinite;
			animation-fill-mode: forwards;

			@media (max-width: 767px) {
				top: rem(-2);
			}

			&:nth-child(1) {
				animation-delay: 0s;
			}

			&:nth-child(2) {
				animation-delay: .3s;
			}

			&:nth-child(3) {
				animation-delay: .7s;
			}
		}
	}

	&__desc {
		font-family: "Futura PT";
		font-style: italic;
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(23);

		@media (max-width: 767px) {
			font-size: rem(8);
			line-height: rem(10);
		}
	}
}

.hero-robot-animation {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: rem(616);
	pointer-events: none;

	@media (max-width: 767px) {
		width: rem(350);
	}

	@media (max-width: 480px) {
		left: 70%;
		width: rem(280);
	}
}

@keyframes dialog-item-ellipsis {
	0% {color: #000000;}
	100% {color: #215AA7;}
}

.btn2 {
	padding: rem(11) rem(16);
	height: rem(74);
	display: inline-flex;
	align-items: center;
	background: #FDFEFF;
	border-radius: rem(1000);
	align-self: center;
	transition: all .2s;
	flex-shrink: 0;

	svg {
		line {
			stroke: #fff;
			transition: all .2s;
		}
	}
	
	&.posbc {
		position: absolute;
		bottom: 0;
		left: 50%;
		right: auto;
		transform: translate(-50%, 50%);
		z-index: 2;
		max-width: none;
		width: auto;
	}

	&:hover {
		text-decoration: none;
		background-color: #215AA7;

		.btn2__ico {
			background-color: #fff;

			svg {
				line {
					stroke: #215AA7;
				}
			}

			&:before {
				border: rem(2) solid #215AA7;
			}

			&:after {
				border: rem(2) solid #215AA7;
			}
		}

		.btn2__desc {
			color: #fff;
		}
	}

	&__ico {
		margin-right: rem(21);
		background: #215AA7;
		border-radius: 50%;
		width: rem(53);
		height: rem(53);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s;
		flex-shrink: 0;

		&:before {
			content: "";
			display: block;
			width: rem(19);
			height: rem(28);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border: rem(2) solid #FFFFFF;
			border-radius: rem(100);
			transition: all .2s;
		}

		&:after {
			content: "";
			display: block;
			width: rem(0);
			height: rem(3);
			position: absolute;
			bottom: rem(18);
			left: 50%;
			transform: translate(-50%, 0%);
			border: rem(2) solid #FFFFFF;
			border-radius: rem(100);
			transition: all .2s;
		}
	}

	&__ico-hover {
		display: none;
	}

	&__desc {
		font-family: "Helvetica Neue";
		font-style: normal;
		font-weight: bold;
		font-size: rem(16);
		line-height: rem(19);
		color: #215AA7;
		transition: all .2s;
		flex-shrink: 0;
		padding-right: rem(30);
	}
}

// -----------------------------------------

.about-razo {
	display: flex;
	background: radial-gradient(rem(936.96) at 45.72% 19.21%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
	padding-bottom: rem(80);
	position: relative;
	justify-content: space-between;

	@media (max-width: 767px) {
		flex-flow: column;
		justify-content: flex-start;
		background: radial-gradient(rem(269.23) at 59.37% 7.5%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
		padding-bottom: rem(40);
	}

	&__center {
		display: flex;
		flex-flow: column;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, 0);

		@media (max-width: 767px) {
			position: static;
			left: auto;
			top: auto;
			transform: none;
		}
	}

	&__left {
		min-height: rem(1);
	}

	&__right {
		padding-right: rem(75);

		@media (max-width: 767px) {
			padding-right: 0;
		}
	}

	&__title {
		font-weight: bold;
		font-size: rem(64);
		line-height: rem(82);
		color: #215AA7;
		margin-left: rem(150);
		margin-top: rem(83);
		margin-bottom: rem(25);

		@media (max-width: 767px) {
			margin-left: rem(20);
			font-size: rem(36);
			line-height: rem(46);
			margin-bottom: rem(5);
			margin-top: rem(20);
			text-align: center;
		}

		@media (max-width: 480px) {
			text-align: left;
		}
	}

	&__title-small {
		@media (max-width: 767px) {
			display: block;
			font-weight: 600;
			text-transform: none;
		}	
	}

	&__title-value {
		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	.razo-img-wrap {
		margin-top: rem(120);
		position: relative;
		margin-left: rem(-158);

		@media (max-width: 767px) {
			margin-left: 0;
			margin-top: rem(80);
			margin-bottom: rem(40);
		}
	}

	.razo-img-desc {
		position: absolute;
		right: rem(275);
		top: rem(-40);

		@media (max-width: 767px) {
			left: 50%;
			right: auto;
		}

		@media (max-width: 480px) {
			top: rem(-30);
		}
	}

	&__razo-img {
		width: rem(688);
		max-width: none;

		@media (max-width: 767px) {
			width: 100%;
			top: 0;
			margin: 0 auto;
		}
	}

	&__razo-2-img-wrap {
		min-height: 1px;
	}

	&__razo-2-img {
		max-width: rem(490);
		width: 100%;
		position: absolute;
		left: rem(517);
		bottom: rem(20);

		@media (max-width: 767px) {
			position: relative;
			width: 50%;
			margin-top: rem(20);
			margin-bottom: 0;
			margin-left: rem(0);
			left: auto;
			bottom: auto;
		}
	}

	&__robot-7 {
		width: rem(194);
		margin: 0 auto;
		margin-top: rem(110);

		@media (max-width: 767px) {
			width: 23%;
			position: absolute;
			top: rem(80);
			left: 10%;
			margin: 0;
		}

		@media (max-width: 480px) {
			width: 29%;
			left: 9%;
			top: 8%;
		}
	}

	&__robot-8 {
		width: rem(277);
		margin: 0 auto;
		margin-top: rem(108);
		position: absolute;
		right: rem(215);
		top: 0;

		@media (max-width: 767px) {
			width: 25%;
			right: rem(40);
		}

		@media (max-width: 480px) {
			right: rem(20);
			margin-top: rem(40);
		}
	}

	&__btn {
		font-weight: bold;
		font-size: rem(16);
		line-height: rem(19);
		align-self: center;
		padding: rem(22) rem(42);

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(17);
			padding: rem(13) rem(40);
		}
	}

	.properties-list {
		margin-top: rem(180);

		@media (max-width: 767px) {
			margin-top: rem(35);
			margin-left: rem(0);
			padding: 0 rem(20);
		}
	}

	.recommendation {
		margin-top: rem(65);

		@media (max-width: 767px) {
			margin: 0;
			margin-top: rem(65);
		}
	}
}

.recommendation {
	font-weight: 450;
	font-size: rem(14);
	line-height: rem(18);
	padding: rem(15) 0;
	padding-left: rem(106);
	padding-right: rem(25);
	background: #D9EEFE;
	border-radius: rem(57);
	position: relative;
	max-width: rem(480);
	overflow: hidden;

	@media (max-width: 767px) {
		margin-left: 0;
		max-width: 100%;
		margin-top: rem(40);
	}

	@media (max-width: 480px) {
		font-size: rem(10);
		line-height: rem(13);
		padding: rem(10) rem(20);
		background: none;

		.chevron {
			display: none;
		}
	}

	.chevron {
		position: absolute;
		top: rem(-10);
		left: rem(52);
	}

	h6 {
		font-weight: bold;
		margin-bottom: rem(10);
	}

	p {
		font-size: rem(14);
		max-width: rem(350);
		width: 100%;
		color: #215AA7;
	}
}

.razo-img-wrap {
	position: relative;
}

.razo-img-desc {
	font-style: italic;
	font-weight: 600;
	font-size: rem(14);
	line-height: 1;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #FFFFFF;
	padding: rem(8) rem(23);
	border-radius: rem(30);
	background: #2D8DD9;
	z-index: 3;

	@media (max-width: 480px) {
		padding: rem(4) rem(12);
		font-size: rem(10);
		line-height: rem(13);
	}
}

.properties-list {
	list-style: none;
	max-width: rem(480);

	@media (max-width: 767px) {
		max-width: 100%;
	}

	li {
		display: flex;
		align-items: center;
		margin-bottom: rem(26);

		@media (max-width: 767px) {
			margin-bottom: rem(15);
		}
	}

	&__ico {
		width: rem(88);
		height: rem(88);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 50%;
		margin-right: rem(54);
		flex-shrink: 0;
		align-self: flex-start;

		@media (max-width: 767px) {
			width: rem(63);
			height: rem(63);
			margin-right: rem(21);
		}
	}

	&__stomach-img {
		width: rem(55);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__clock-img {
		width: rem(54);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__timer-img {
		width: rem(61);

		@media (max-width: 767px) {
			width: 65%;
		}
	}

	&__plate-img {
		width: rem(48);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__test-img {
		width: rem(48);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__pills2-img {
		width: rem(51);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	p {
		font-weight: 450;
		font-size: rem(18);
		line-height: rem(21);
		margin-top: rem(10);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			padding-right: rem(5);

			br {
				display: none;
			}
		}
	}
}

// ----------------------------------------

.articles {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;

	@media (max-width: 767px) {
		flex-flow: column;
	}

	.article-prev {
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

.article-prev {
	min-height: rem(650);
	position: relative;
	padding-top: rem(118);
	padding-bottom: rem(85);
	overflow: hidden;

	@media (max-width: 767px) {
		padding-top: rem(34);
		padding-bottom: rem(25);
		min-height: rem(320);
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: radial-gradient(rem(247.50) at 50% 50%, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0) 100%);
		}
	}

	&__bg-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
		max-width: none;
		min-height: 100%;

		@media (max-width: 767px) {
			max-width: 100%;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
		display: flex;
		flex-flow: column;
		align-items: center;
		padding: 0 rem(20);
		width: 100%;
		height: 100%;
	}

	&__ico {
		margin: rem(0) auto;
		margin-bottom: rem(20);
		width: rem(78);
		height: rem(78);
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFFFFF;
		border-radius: 50%;
		flex-shrink: 0;

		@media (max-width: 767px) {
			width: rem(38);
			height: rem(38);
			margin-bottom: rem(13);
		}
	}

	&__ico-img {
		width: rem(42);

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__title {
		font-weight: bold;
		font-size: rem(46);
		line-height: rem(50);
		text-align: center;
		color: #215AA7;
		margin-top: rem(45);

		@media (max-width: 767px) {
			margin-top: rem(13);
			font-size: rem(24);
			line-height: rem(28);
		}

		span {
			display: block;

			&:first-child {
				position: relative;
				margin-bottom: rem(45);

				@media (max-width: 767px) {
					margin-bottom: rem(20);
				}

				&:after {
					content: "";
					display: block;
					width: rem(25);
					border-radius: rem(50);
					border: rem(2) solid #215AA7;
					box-sizing: border-box;
					position: absolute;
					bottom: rem(-29);
					left: 50%;
					transform: translate(-50%, 0);

					@media (max-width: 767px) {
						bottom: rem(-18);
						border: rem(1) solid #215AA7;
					}
				}
			}

			&:last-child {
				font-weight: 900;
				font-size: rem(26);
				line-height: rem(40);
				color: #153E75;

				@media (max-width: 767px) {
					font-size: rem(13);
					line-height: rem(14);
					margin-top: rem(32);
				}
			}
		}
	}

	&__btn {
		margin: 0 auto;
		margin-top: rem(60);
		min-width: rem(130);

		@media (max-width: 767px) {
			margin-top: rem(40);
			font-size: rem(12) !important;
			height: rem(44) !important;
		}
	}
}

// -----------------------------------------

.chevron-wrap {
	width: rem(63);
	height: rem(63);
	border-radius: 50%;
	background: #D8EEFE;
	overflow: hidden;
	position: relative;

	.chevron {
		position: absolute;
		top: rem(-20) !important;
		left: 50% !important;
		transform: translate(-50%, 0);
		transition: top .2s;
	}
}
 
.chevron {
	width: rem(26);
	height: rem(45);
}
 
.chevron::before {
	content: "";
	position: absolute;
	bottom: rem(-14);
	margin-top: 0;
	border-width: rem(3) rem(13) rem(13) rem(13);
	border-style: solid;
	border-color:  #215AA7  #215AA7 transparent #215AA7 ;
	left: 0;
}

.chevron::after {
	content: "";
	position: absolute;
	background: #215AA7;
	width: rem(26);
	height: rem(45);	
	display: block;
	position: absolute;
}

.literature-wrap {
	margin-top: rem(110);

	@media (max-width: 767px) {
		margin-top: rem(50);
	}
}
 
.literature-margin {
	margin: rem(50) 0;
	margin-bottom: rem(100);

	@media (max-width: 767px) {
		margin: rem(20) 0;
	}
}
 
.literature {
	background: #F7FBFF;
	position: relative;

	.container {
		padding-top: rem(20);
		padding-bottom: rem(20);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 767px) {
			padding-top: rem(35);
			padding-bottom: rem(35);
			justify-content: flex-start;
		}
	}

	.chevron-wrap {
		position: absolute;
		top: rem(14);
		left: rem(46);

		@media (max-width: 767px) {
			left: rem(15);
			top: rem(25);
		}
	}

	.chevron {
		position: absolute;
		left: rem(55);
		top: rem(0);

		@media (max-width: 767px) {
			left: rem(20);
		}
	}

	&__link {
		font-weight: 900;
		font-size: rem(14);
		line-height: rem(18);
		color: #215AA7;
		border-bottom: rem(2) dashed #C1DDF1;
		display: inline-block;
		margin-left: rem(60);

		@media (max-width: 480px) {
			font-size: rem(12);
			line-height: rem(15);
			margin-left: rem(45);
		}

		&:hover {
			text-decoration: none;
			border-bottom: rem(2) solid #C1DDF1;
		}
	}

	&__btn {
		padding: rem(13) 0;
		width: rem(123);
		background: #D8EEFE;
		color: #215AA7;
		margin-right: rem(5);
		border: none;

		&:hover {
			border: none;
			background: #215AA7 !important;
			color: #fff; 
		}
	}

	&.about-product {
		overflow: hidden;

		@media (max-width: 767px) {
			margin-top: rem(25);
		}

		&.open {
	
			.chevron {
				top: rem(-5) !important;
			}
		}	

		.chevron-wrap {
			top: 50%;
			transform: translate(0, -50%);

			@media (max-width: 767px) {
				left: rem(15);
			}
		}

		.literature__link {
			border-bottom: none;
			font-size: rem(24);
			line-height: rem(40);
			margin-left: rem(110);

			@media (max-width: 767px) {
				font-size: rem(18);
				line-height: rem(23);
				margin-left: rem(90);
			}
		}

		.container {
			padding-right: rem(30);

			@media (max-width: 767px) {
				padding-top: rem(45);
				padding-bottom: rem(45);
			}
		}

		.literature__btn {
			margin-right: 0;
			background: #D8EEFE;
			height: rem(60);
			width: rem(150);
		}

		&:hover {
			background: none;
			border: rem(2) dashed #C1DDF1;
		}
	}
}

.bibliography {

	&__cover {
		padding: 0 rem(55);
		background-color: #fff;
		overflow: hidden;
		height: 0;
		transition: height .3s ease;

		@media (max-width: 767px) {
			padding: 0 rem(20);
		}
	}

	&__list {
		list-style: none;

		li {
			padding: rem(25) rem(60);
			display: flex;
			border-bottom: rem(4) solid #F7FBFF;
			min-height: rem(90);

			@media (max-width: 767px) {
				padding: rem(10) rem(0);
				min-height: auto;
			}

			&:last-child {
				border-bottom: none;
			}

			.num {
				display: block;
				width: rem(21);
				height: rem(21);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background: #153E75;
				font-weight: 500;
				font-size: rem(14);
				color: #FFFFFF;
				flex-shrink: 0;
				margin-right: rem(40);
				margin-top: rem(3);

				@media (max-width: 767px) {
					margin-right: rem(20);
					width: rem(15);
					height: rem(15);
					font-size: rem(9);
				}
			}

			p {
				font-weight: 450;
				font-size: rem(18);
				line-height: rem(23);
				max-width: rem(1135);
				color: #0C284E;

				@media (max-width: 767px) {
					font-size: rem(12);
					line-height: rem(15);
				}
			}
		}
	}
}

// -----------------------------------------

.enough-razo {
	background: url("../img/images/img-6.jpg") no-repeat 50% / cover,  linear-gradient(180deg, rgba(255, 255, 255, 0) 38.75%, #FFFFFF 100%), #F6F6F6;
	position: relative;
	min-height: 1px;

	@media (max-width: 767px) {
		background-position: 0 0;
		background-size: contain;
		padding-bottom: rem(34);
	}

	@media (max-width: 480px) {
		background-size: rem(470);
	}

	.razo-img-wrap {
		margin-left: rem(-175);

		@media (max-width: 767px) {
			width: 100%;
			margin-left: 0;
		}

		@media (max-width: 480px) {
			margin-top: rem(55);
		}
	}

	
	.razo-img-desc {
		right: rem(435);

		@media (max-width: 767px) {
			right: auto;
			left: rem(40);
		}

		@media (max-width: 480px) {
			
			left: rem(20);
		}
	}

	.about-razo {

		&__title {
			font-weight: bold;
			font-size: rem(48);
			line-height: rem(62);
			text-transform: uppercase;
			margin-right: rem(-60);
			margin-bottom: rem(10);
			margin-left: rem(130);
			margin-top: rem(128);

			@media (max-width: 767px) {
				margin: 0;
				margin-top: rem(35);
				margin-left: rem(40);
				text-align: left;
				font-size: rem(30);
				line-height: rem(35);
			}

			@media (max-width: 480px) {
				margin-top: rem(35);
				margin-left: rem(20);
				font-size: rem(18);
				line-height: rem(23);
			}
		}

		&__robot-7 {
			margin-top: rem(200);

			@media (max-width: 767px) {
				margin-top: rem(40);
				left: auto;
				right: rem(40);
				top: 0;
			}
			
			@media (max-width: 480px) {
				width: rem(86);
				right: rem(25);
			}
		}

		&__desc {
			margin-top: 0;
			margin-left: rem(130);

			@media (max-width: 767px) {
				display: none;
			}
		}

		.recommendation {
			margin-left: 0;
		}
		
		&__razo-img {
			@media (max-width: 767px) {
				width: 80%;
				margin-left: -20%;
			}

			@media (max-width: 480px) {
				width: 70%;
				margin-left: -17%;
			}
		}
	}

	
	.properties-list {
		margin-left: 0;
		margin-top: rem(140);

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}

	.btn2 {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
		z-index: 2;
	
		@media (max-width: 767px) {
			display: none;
		}
	}
}

.article-video-medical {
	margin-top: rem(120);
}

.medical-razo {
	flex-flow: column;
	background: url("../img/images/img-9.jpg") no-repeat 50% / cover,  linear-gradient(180deg, rgba(255, 255, 255, 0) 38.75%, #FFFFFF 100%), #F6F6F6;
	padding-bottom: rem(30);
	min-height: 1px;

	.about-razo__title {
		margin-left: rem(130);

		@media (max-width: 767px) {
			margin-left: 0;
		}

		@media (max-width: 480px) {
			margin-left: rem(20);
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	.properties-list {
		margin-left: rem(130);
		margin-top: rem(20);

		@media (max-width: 767px) {
			margin-left: 0;
		}
	}

	.about-razo__title {
		text-transform: uppercase;
	}
}

// -----------------------------------------

.pass-test-wrap {
	min-height: 1px;
}

.pass-test {
	background: radial-gradient(rem(691.68) at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
	padding-top: rem(60);
	display: flex;
	flex-flow: column;
	padding-bottom: rem(62);
	position: relative;

	&__title {
		margin-top: rem(60);
		font-weight: bold;
		font-size: rem(48);
		line-height: rem(62);
		text-align: center;
		text-transform: uppercase;
		color: #215AA7;
		margin-bottom: rem(40);

		@media (max-width: 767px) {
			margin-top: rem(15);
			font-size: rem(33);
			line-height: rem(42);
			margin-bottom: rem(7);
		}
	}

	&__desc {
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(26);
		text-align: center;
		max-width: rem(510);
		margin: 0 auto;
		color: #153E75;
		margin-bottom: rem(45);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			margin-bottom: rem(20);
		}

		@media (max-width: 480px) {
			br {
				display: none
			}
		}
	}

	&__btn {
		display: flex;
		padding: rem(22) rem(70);
		max-width: rem(230);
		margin: rem(50) auto;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(17);
			padding: rem(19) rem(50);
			max-width: rem(200);
		}
	}


	&__robot-img {
		max-width: rem(405);
		width: 100%;
		position: absolute;
		left: rem(80);
		top: rem(60);
		align-self: flex-start;

		@media (max-width: 767px) {
			max-width: 50%;
			position: static;
			margin: 0 auto;
			left: auto;
			top: auto;
		}

		@media (max-width: 480px) {
			max-width: 63%;
			width: 100%;
		}
	}
}

.robot-speak {
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
	border-radius: rem(100);
	position: relative;
	padding: rem(45);
	max-width: rem(650);
	text-align: center;
	margin: 0 auto;
	font-style: italic;
	font-weight: bold;
	font-size: rem(24);
	line-height: rem(31);
	text-transform: uppercase;
	color: #215AA7;
	position: relative;

	@media (max-width: 767px) {
		padding: rem(25) rem(30);
		font-size: rem(14);
		line-height: rem(18);
		position: absolute;
		bottom: 16%;
		left: 50%;
		transform: translate(-50%, 0);
		width: 95%;
		max-width: rem(400);
	}

	&:before {
		content: "";
		display: block;
		border: rem(39) solid transparent;
		border-top: rem(25) solid #fff;
		position: absolute;
		left: rem(-27);
		top: rem(74);
		transform: rotate(-5deg);

		@media (max-width: 767px) {
			left: 57%;
			top: rem(-7);
			transform: rotate(17deg);
		}
	}

	&__small {
		font-style: italic;
		font-weight: bold;
		font-size: rem(18);
		line-height: rem(35);
		color: #153E75;
		text-transform: none;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
			padding: 0 rem(25);
		}
	}
}

.section-title {
	color: #215AA7;
	font-weight: bold;
	font-size: rem(48);
	line-height: rem(62);
	text-transform: uppercase;

	@media (max-width: 767px) {
		font-size: rem(30);
		line-height: rem(38);

		br {
			display: none;
		}
	}
}

// ------------------------------------------

.instruction {
	padding-top: rem(115);
	background: #fff;
	padding-bottom: rem(60);

	@media (max-width: 767px) {
		padding-top: rem(55);
		padding-bottom: rem(20);
	}

	.container-2 {
		display: flex;
		flex-flow: column;
	}

	&__head {
		display: flex;
		margin-bottom: rem(100);

		@media (max-width: 767px) {
			flex-flow: column;
			margin-bottom: rem(50);
		}
	}

	.section-title {
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
			margin-bottom: rem(30);
		}
	}

	&__specifications {
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
		}

		p {
			font-size: rem(21);
			line-height: rem(34);
			color: #0C284E;

			@media (max-width: 767px) {
				font-size: rem(14);
				line-height: rem(21);
			}

			b {
				font-weight: 600;
			}
		}
	}
}

.about-product {
	background: #FFFFFF;
	border: rem(2) dashed #C1DDF1;
	box-sizing: border-box;
	border-radius: rem(50);
	margin-bottom: rem(31);
	transition: all .5s;

	@media (max-width: 767px) {
		margin-bottom: rem(15);
		border-radius: rem(32);
	}

	@media (max-width: 480px) {
		margin-bottom: rem(10);
		border-radius: rem(25);
	}

	&:hover {
		border: rem(2) dashed #215AA7;
		background: #215AA7;

		.about-product__head p {
			color: #FFFFFF;
		}
	}

	&.open {
		background: #FFFFFF;
		border: rem(2) dashed #C1DDF1;
		
		.about-product__head {
			background: #215AA7;
			border-radius: rem(50) rem(50) 0 0;
			margin: rem(-2);
			padding: rem(22) rem(46);

			@media (max-width: 767px) {
				border-radius: rem(25) rem(25) 0 0;
				padding: rem(10) rem(13);
			}
		}

		.about-product__head p {
			color: #fff;
		}
		
	}

	&__head {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: rem(20) rem(44);
		padding-right: rem(30);

		@media (max-width: 767px) {
			padding: rem(8) rem(11);
		}

		span {
			width: rem(63);
			height: rem(63);
			background: #D8EEFE;
			display: block;
			border-radius: 50%;
			font-family: 'Helvetica Neue';
			font-weight: 900;
			font-size: rem(24);
			line-height: rem(29);
			text-align: center;
			text-transform: uppercase;
			color: #215AA7;
			margin-right: rem(57);
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;

			@media (max-width: 767px) {
				margin-right: rem(17);
				width: rem(34);
				height: rem(34);
				font-size: rem(14);
				line-height: rem(17);
			}
		}

		p {
			font-weight: 900;
			font-size: rem(24);
			line-height: rem(28);
			color: #153E75;

			@media (max-width: 767px) {
				font-size: rem(14);
				line-height: rem(18);
			}
		}
	}

	&__text {
		padding: rem(50) rem(165);
		padding-top: rem(25);
		font-size: rem(18);
		line-height: rem(23);

		@media (max-width: 767px) {
			padding: rem(20) rem(40);
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&__spoiler {
		overflow: hidden;
		height: 0;
		@include transition;
	}

	&.research {
		margin-top: rem(50);

		@media (max-width: 767px) {
			margin-top: rem(20);
		}

		.about-product__head {

			span {
				background-color: #215AA7 !important;
			}
		}

		.open-book {
			width: rem(27);

			@media (max-width: 767px) {
				width: 60%;
			}
		}
	}
}

.tell-you-wrap {
	margin-top: rem(73);
	padding: rem(90) 0;
	padding-top: 0;
	padding-bottom: rem(95);

	@media (max-width: 767px) {
		padding: 0;
		padding-top: rem(40);
		margin-top: rem(50);
		padding-bottom: rem(20)
	}
	
	&.type-cyan {
		background: #215AA7;

		@media (min-width: 768px) {
			padding: 0;
		}

		.tell-you {
			@media (min-width: 768px) {
				min-height: rem(267);
				max-width: 100%;
			}

			&__robot-7 {

				@media (min-width: 768px) {
					left: rem(240);
					bottom: rem(0);
					top: auto;
					width: rem(143);
				}
			}

			&__razo-img {

				@media (min-width: 768px) {
					width: rem(480);
					right: rem(150);
					top: rem(40);
					left: auto;
				}
			}

			&__robot-say {

				@media (min-width: 768px) {
					left: 36%;
					right: auto;
					top: rem(62);
					background-color: #fff;
				}

				&:before {

					@media (min-width: 768px) {
						left: rem(-60);
						top: rem(61);
						background-color: #fff;
					}
				}

				&:after {

					@media (min-width: 768px) {
						left: rem(-95);
						top: rem(30);
						background-color: #fff
					}
				}
			}
		}
	}
}

.tell-you {
	max-width: rem(730);
	width: 100%;
	min-height: rem(310);
	position: relative;
	margin: 0 auto;

	&__robot-7 {
		width: rem(140);
		position: absolute;
		left: 0;
		top: rem(-20);

		@media (max-width: 767px) {
			left: rem(20);
			min-height: rem(115);
			top: rem(110);
			width: rem(80);
			z-index: 3;
		}
	}

	&__razo-img {
		width: rem(392);
		position: absolute;
		left: 12%;
		bottom: 0;

		@media (max-width: 767px) {
			left: auto;
			right: rem(20);
			width: rem(250);
			bottom: rem(20);
		}
	}

	&__robot-say {
		position: absolute;
		right: 0;
		top: rem(0);
		background: #F2FAFF;
		border-radius: rem(100);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(38) rem(34);
		padding-left: rem(55);
		z-index: 2;

		@media (max-width: 767px) {
			position: relative;
			width: 90%;
			max-width: rem(500);
			margin: 0 auto;
			right: auto;
			top: auto;
			z-index: 2;
			flex-flow: column;
			padding: rem(20) rem(15);

		}

		&:before,
		&:after {
			content: "";
			display: block;
			border-radius: 50%;
			background: #F2F9FF;
			position: absolute;
		}
		&:before {
			width: rem(35);
			height: rem(35);
			left: rem(-50);
			top: rem(10);

			@media (max-width: 767px) {
				left: 50%;
				top: auto;
				bottom: rem(-70);
				width: rem(19);
				height: rem(19);
			}
		}

		&:after {
			width: rem(21);
			height: rem(21);
			left: rem(-80);
			top: rem(60);

			@media (max-width: 767px) {
				left: 40%;
				top: auto;
				width: rem(12);
				height: rem(12);
				bottom: rem(-40);
			}
		}
	}

	&__desc {
		font-style: italic;
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(23);
		margin-right: rem(50);
		color: #0C284E;

		@media (max-width: 767px) {
			margin-right: 0;
			margin-bottom: rem(15);
			font-size: rem(14);
			line-height: rem(18);

			br {
				display: none;
			}
		}
	}

	&__btn {
		padding: rem(22) rem(40);
		font-size: rem(16);
		line-height: rem(19);

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(17);
			padding: rem(16) rem(40);
		}
	}
}

// ---------------------------------------

.question {
	padding-top: rem(80);
	background-color: #fff;
	padding-bottom: rem(90);

	@media (max-width: 767px) {
		padding-top: rem(20);
		padding-bottom: rem(30);
	}

	&__cover {
		background: #F6FBFF;
		border-radius: rem(50);
		padding: rem(40) rem(85);
		padding-bottom: rem(135);
		position: relative;

		@media (max-width: 767px) {
			padding: rem(30) rem(0);
			padding-bottom: rem(70);
			border-radius: rem(25);
			background: none;
		}
	}

	&__content {
		position: relative;
		z-index: 3;
	}

	&__title {
		margin-bottom: rem(21);
		margin-top: rem(60);
	
		@media (max-width: 767px) {
			margin-top: rem(30);
			font-size: rem(22);
			margin-bottom: rem(10);
			text-align: center;
		}
	}

	&__desc {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		color: #153E75;
		margin-bottom: rem(65);

		@media (max-width: 767px) {
			margin-bottom: rem(30);
			font-size: rem(18);
			line-height: rem(23);
			text-align: center;
		}
	}

	&__robot-img {
		width: rem(293);
		position: absolute;
		top: rem(-40);
		right: rem(140);

		@media (max-width: 767px) {
			display: none;
		}
	}
}

.question-form {
	display: flex;

	@media (max-width: 767px) {
		flex-flow: column;
	}

	&__col {
		position: relative;
		width: 100%;

		&:first-child {
			padding-right: rem(23);
			width: rem(350);
			flex-shrink: 0;
			z-index: 1;

			@media (max-width: 767px) {
				box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
				width: 100%;
				padding-right: 0;
				border-radius: rem(50);
				margin-bottom: rem(20);
			}
		}

		&:last-child {
			z-index: 2;

			@media (max-width: 767px) {
				box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
				border-radius: rem(50);
				padding: rem(15) rem(20);
				padding-top: rem(30);
			}

			input {
				padding-right: rem(277);

				@media (max-width: 767px) {
					margin-bottom: rem(30);
					padding-right: rem(30);
				}
			}
		}
	}

	input {
		height: rem(80);
		padding: 0 rem(42);
		background: #FFFFFF;
		box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
		border-radius: rem(100);
		width: 100%;
		outline: none;
		border: none;
		display: block;
		font-size: rem(21);
		font-family: "Futura PT";
		color: #0C284E;

		@media (max-width: 767px) {
			width: 100%;
			height: rem(60);
			padding: 0 rem(30);
			box-shadow: none;
			font-size: rem(12);
			margin-bottom: 0;
			text-align: center;
		}

		&::placeholder { 
			opacity: 1;
			color: #000;
			font-size: rem(21);
			line-height: rem(34);
			font-family: "Futura PT";
			color: #0C284E;

			@media (max-width: 767px) {
				font-size: rem(12);
			}
		}
	}

	button  {
		position: absolute;
		top: 50%;
		right: rem(16);
		padding: 0 rem(50);
		height: rem(58);
		border: none;
		cursor: pointer;
		outline: none;
		transform: translate(0, -50%);
		width: rem(238);
		display: flex;

		@media (max-width: 767px) {
			position: static;
			transform: translate(0);
			margin: 0 auto;
		}

		@media (max-width: 480px) {
			width: 100%;
		}
	}
}

// ------------------------------------------

.politics {
	padding-bottom: rem(350);

	@media (max-width: 767px) {
		padding-bottom: rem(80);
	}

	&__title {
		margin-bottom: rem(70);
	}

	&__h5 {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		margin: rem(70) 0;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
			margin: rem(20) 0;
		}
	}

	&__p {
		font-size: rem(21);
		line-height: rem(34);
		margin: rem(35) 0;
		max-width: rem(1136);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(17);
		}
	}
}

// ------------------------------------------

.not-found {
	padding-top: rem(45);
	padding-bottom: rem(65);
	min-height: 1px;

	&__title {
		font-weight: bold;
		font-size: rem(48);
		line-height: rem(50);
		margin-top: rem(40);
		text-align: center;
		text-transform: uppercase;
		color: #215AA7;
		margin-bottom: rem(20);

		@media (max-width: 767px) {
			font-size: rem(24);
			line-height: rem(28);
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		text-align: center;
		color: #0C284E;
		margin-bottom: rem(5);
		
		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(22);
		}

		a {
			color: #215AA7;
			font-weight: 900;
		}
	}

	&__img {
		max-width: rem(777);
		width: 100%;
		margin: 0 auto;
		position: relative;
		left: rem(50);
		
		@media (max-width: 767px) {
			left: auto;
			width: 80%;
		}
	}
}