@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-Book.eot");
  src: url("../fonts/FuturaPT/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-Medium.eot");
  src: url("../fonts/FuturaPT/FuturaPT-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-Medium.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-Demi.eot");
  src: url("../fonts/FuturaPT/FuturaPT-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-Demi.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-DemiObl.eot");
  src: url("../fonts/FuturaPT/FuturaPT-DemiObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-DemiObl.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-DemiObl.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-Bold.eot");
  src: url("../fonts/FuturaPT/FuturaPT-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-Bold.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url("FuturaPT-BoldObl.eot");
  src: url("../fonts/FuturaPT/FuturaPT-BoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-BoldObl.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-BoldObl.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura PT';
  src: url("../fonts/FuturaPT/FuturaPT-Heavy.eot");
  src: url("../fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPT-Heavy.woff") format("woff"), url("../fonts/FuturaPT/FuturaPT-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url("../fonts/FuturaPT/FuturaPTCond-Book.eot");
  src: url("../fonts/FuturaPT/FuturaPTCond-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT/FuturaPTCond-Book.woff") format("woff"), url("../fonts/FuturaPT/FuturaPTCond-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.eot");
  src: url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.woff") format("woff"), url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.eot");
  src: url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.woff") format("woff"), url("../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* 
HTML5 Doctor Reset v1.6.1
http://html5doctor.com/html-5-reset-stylesheet/
CSSresetr
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

img {
  display: block;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (min-width: 2100px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 2300px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 2500px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 2700px) {
  html {
    font-size: 22px;
  }
}

@media (min-width: 2900px) {
  html {
    font-size: 24px;
  }
}

@media (min-width: 3100px) {
  html {
    font-size: 27px;
  }
}

@media (min-width: 3300px) {
  html {
    font-size: 30px;
  }
}

@media (max-width: 1575px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1380px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1190px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 990px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 7.5px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 16px;
  }
}

body {
  background: url("../img/images/main-bg.jpg"), #E5E5E5;
  color: #0C284E;
  font-family: "Futura PT";
  position: relative;
  font-size: 1.3125rem;
  line-height: 2.125rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.main {
  background: #fff;
  display: flex;
  flex-flow: column;
}

@media (max-width: 767px) {
  .main {
    padding-top: 4.0625rem;
  }
}

.wrapper {
  max-width: 97.1875rem;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0rem 0.25rem 1.0625rem rgba(255, 255, 255, 0.25);
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 3.4375rem;
}

@media (max-width: 767px) {
  .container {
    padding: 0 1.25rem;
  }
}

.container-2 {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 8rem;
}

@media (max-width: 767px) {
  .container-2 {
    padding: 0 1.25rem;
  }
}

.btn {
  background: #215AA7;
  border-radius: 62.5rem;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
}

.btn:hover {
  text-decoration: none;
  background-color: #153E75  !important;
}

@media (min-width: 768px) {
  .mob-visible {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .desk-visible {
    display: none !important;
  }
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.os-scrollbar-vertical {
  margin-right: 0.75rem;
  background: rgba(255, 255, 255, 0.5) !important;
  border: 0.0625rem solid #DFEAF8 !important;
  box-sizing: border-box !important;
  width: 0.625rem !important;
}

@media (max-width: 767px) {
  .os-scrollbar-vertical {
    margin-right: 0;
  }
}

.os-theme-dark.os-host-transition > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #D8EEFE !important;
  border-radius: 3.125rem !important;
  width: 0.25rem !important;
}

.header .container {
  display: flex;
  height: 11.25rem;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: block;
  width: 100%;
  max-width: 17.1875rem;
}

.logo img {
  width: 100%;
}

.nav {
  display: flex;
  width: 100%;
  margin: 0 6.25rem;
  list-style: none;
  align-items: center;
  justify-content: space-between;
}

.nav a {
  font-family: "Helvetica Neue";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #0c284e;
}

.nav a:hover {
  text-decoration: none;
  color: #215aa7;
}

.nav a.active {
  color: #215aa7;
}

@media (max-width: 767px) {
  .dropdown-link {
    width: 100%;
  }
}

.dropdown-link > a {
  position: relative;
}

.dropdown-link > a:after {
  position: absolute;
  top: 50%;
  right: -1.25rem;
  display: block;
  width: 0.5rem;
  height: 0.4375rem;
  content: "";
  transform: translate(0, -50%);
  background: url("../img/icons/menu-item-arrow.png") no-repeat 50%/0.5rem;
}

.dropdown-link > a:before {
  position: absolute;
  top: 4.6875rem;
  left: 50%;
  display: block;
  display: none;
  content: "";
  transform: translate(-50%, 0);
  border: 0.625rem solid transparent;
  border-bottom: 0.8125rem solid #215aa7;
}

.dropdown-link.open .sub-nav {
  pointer-events: auto;
  opacity: 1;
}

.dropdown-link.open > a:before {
  display: block;
}

.dropdown-link.open > a:after {
  top: 37%;
  transform: rotate(-180deg);
}

.header-btn {
  height: 3.75rem;
  padding: 0 2.9375rem;
  white-space: nowrap;
  flex-shrink: 0;
}

.mob-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.mob-header .container {
  height: 4.0625rem;
}

.mob-header .logo {
  max-width: 7.5625rem;
}

.menu-btn {
  display: block;
  width: 1.875rem;
  height: 1.0625rem;
  transition: all .3s ease;
}

.menu-btn.close-menu .line {
  position: relative;
}

.menu-btn.close-menu .line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close-menu .line:nth-child(1) {
  top: 0.4375rem;
  transform: rotate(45deg);
}

.menu-btn.close-menu .line:nth-child(3) {
  bottom: 0.4375rem;
  transform: rotate(-45deg);
}

.menu-btn:hover {
  height: 1.0625rem;
}

.menu-btn .lines {
  display: block;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
}

.menu-btn .line {
  display: block;
  width: 100%;
  height: 0.1875rem;
  transition: all .2s ease;
  border-radius: 0.625rem;
  background: #0c284e;
}

.mob-menu {
  position: fixed;
  z-index: 40;
  top: 0%;
  width: 100%;
  padding: 4.0625rem 1.25rem;
  transition: all .5s ease;
  transform: translate(0, -100%);
  background-color: #ffffff;
  max-height: 100vh;
  overflow: auto;
}

@media (min-width: 768px) {
  .mob-menu {
    display: none !important;
  }
}

.mob-menu.open {
  transform: translate(0, 0%);
}

.mob-menu .nav {
  margin: 0;
  padding: 1.25rem;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .mob-menu .nav {
    padding: 1.25rem 0;
  }
}

.mob-menu .nav li {
  margin: 0.625rem 0;
}

.mob-menu .nav a {
  font-size: 1.125rem;
  line-height: 1.3125rem;
}

.sub-nav {
  position: absolute;
  z-index: 20;
  top: 100%;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 9.6875rem;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  background: #215aa7;
  align-items: center;
  justify-content: center;
  transition: all .2s;
}

@media (max-width: 767px) {
  .sub-nav {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    width: 100%;
    height: auto;
    padding: 0;
    pointer-events: auto;
    opacity: 1;
    flex-flow: column;
    padding: 1.25rem 0;
    background: #F2F7FB;
  }
}

.sub-nav__li {
  position: relative;
  padding: 0 5rem;
}

@media (max-width: 767px) {
  .sub-nav__li {
    padding: 0 1.25rem;
    width: 100%;
    text-align: left;
  }
  .sub-nav__li br {
    display: none;
  }
}

.sub-nav__li:after {
  position: absolute;
  top: 0.8125rem;
  right: 0;
  display: block;
  width: 0.125rem;
  height: 2.5rem;
  content: "";
  background-color: #346ab3;
}

@media (max-width: 767px) {
  .sub-nav__li:after {
    display: none;
  }
}

.sub-nav__li:last-child:after {
  display: none;
}

.sub-nav__li a {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: flex;
  text-transform: uppercase;
  color: #ffffff;
  align-items: center;
}

@media (max-width: 767px) {
  .sub-nav__li a {
    align-items: flex-start;
    text-align: left;
    color: #0C284E;
  }
}

.sub-nav__li a:hover {
  text-decoration: underline;
  color: #ffffff;
}

@media (max-width: 767px) {
  .sub-nav__li a:hover {
    color: #215AA7;
    text-decoration: none;
  }
}

.sub-nav__ico {
  margin-right: 2.5rem;
}

@media (max-width: 767px) {
  .sub-nav__ico {
    display: none;
  }
}

.sub-nav__ico-1 {
  width: 3.6875rem;
}

.sub-nav__ico-2 {
  width: 5.1875rem;
}

.sub-nav__ico-3 {
  width: 5.0625rem;
}

.sub-nav__ico-4 {
  width: 3.75rem;
}

.sub-nav__ico-5 {
  width: 5.3125rem;
}

.contraindications {
  background: #D9EEFE;
}

.contraindications h3 {
  font-family: 'Futura PT Cond Book';
  font-size: 2.5625rem;
  line-height: 3.3125rem;
  padding: 0.1875rem 0;
  letter-spacing: -0.01em;
  color: #95B6E2;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .contraindications h3 {
    font-size: 1.5625rem;
    line-height: 1.875rem;
    text-align: left;
    padding: 0.625rem 0;
  }
}

@media (max-width: 480px) {
  .contraindications h3 {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.footer {
  background: #F2F7FB;
}

.footer .logo {
  max-width: 11.125rem;
  margin-bottom: 3.5rem;
}

@media (max-width: 767px) {
  .footer .logo {
    max-width: 10.6875rem;
  }
}

.footer__row {
  display: flex;
  padding-bottom: 2.25rem;
  padding-top: 2.375rem;
}

@media (max-width: 767px) {
  .footer__row {
    flex-flow: column;
  }
}

.footer__col:nth-child(1) {
  width: 18%;
}

.footer__col:nth-child(2) {
  width: 24%;
}

.footer__col:nth-child(3) {
  width: 24%;
}

.footer__col:nth-child(4) {
  width: 20%;
}

@media (max-width: 767px) {
  .footer__col {
    width: 100% !important;
  }
}

.footer__cover {
  padding-top: 1.875rem;
}

@media (max-width: 767px) {
  .footer__cover {
    padding-top: 0;
  }
}

.footer .bordbtm {
  border-bottom: 0.1875rem solid #DFEAF8;
}

@media (max-width: 767px) {
  .footer .bordbtm {
    padding-bottom: 0;
  }
}

.footer__link {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  color: #215AA7;
  display: block;
}

@media (max-width: 767px) {
  .footer__link {
    margin-bottom: 0.9375rem;
  }
}

@media (max-width: 767px) {
  .footer .btn2__ico {
    margin-right: 0;
  }
}

.footer .btn2__ico:before, .footer .btn2__ico:after {
  display: none;
}

@media (max-width: 767px) {
  .footer .btn2 {
    padding: 0;
    height: auto;
    background: none;
    align-self: flex-start;
    width: auto;
  }
}

@media (max-width: 767px) {
  .footer .btn2__desc {
    display: none;
    background-color: #fff;
  }
}

@media (max-width: 767px) {
  .footer .btn2:hover {
    background-color: #153E75;
  }
}

@media (max-width: 767px) {
  .footer .btn2:hover .btn2__ico {
    background-color: #153E75;
  }
}

@media (max-width: 767px) {
  .footer .btn2:hover .btn2__ico svg line {
    stroke: #fff;
  }
}

@media (max-width: 767px) {
  .logo-col {
    display: flex;
    justify-content: space-between;
  }
}

.footer-nav {
  margin-bottom: 1.5625rem;
}

@media (max-width: 767px) {
  .footer-nav {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 767px) {
  .footer-nav--last {
    margin-bottom: 1.5625rem;
  }
}

.footer-nav__title {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #215AA7;
  padding-bottom: 1.5625rem;
  margin-bottom: 1.5625rem;
  border-bottom: 0.1875rem solid #DFEAF8;
  min-width: 12.5625rem;
  display: inline-block;
}

@media (max-width: 767px) {
  .footer-nav__title {
    width: 100%;
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}

.footer-nav__list {
  list-style: none;
  padding: 0;
}

.footer-nav__list li {
  display: block;
  line-height: 1;
}

.footer-nav__list a {
  font-weight: 450;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .footer-nav__list a {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
}

.scroll-up {
  padding: 0.8125rem 1.0625rem;
  background-color: #fff;
  padding-right: 1.25rem;
  flex-shrink: 0;
}

.scroll-up:hover {
  background-color: #fff;
  text-decoration: none;
  box-shadow: 0rem 0rem 1.5625rem -0.1875rem rgba(0, 0, 0, 0.25);
}

@media (max-width: 767px) {
  .scroll-up {
    padding: 0;
    height: auto;
    background: none;
    align-self: flex-start;
  }
}

.scroll-up__round {
  background-color: #215AA7;
  border-radius: 50%;
  width: 3.25rem;
  height: 3.25rem;
  margin-right: 1.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .scroll-up__round {
    margin-right: 0;
  }
}

.scroll-up__ico {
  width: 1rem;
}

.scroll-up__ico:before, .scroll-up__ico:after {
  display: none;
}

.scroll-up__desc {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #215AA7;
  text-align: left;
}

@media (max-width: 767px) {
  .scroll-up__desc {
    display: none;
  }
}

.copyright {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  color: #0C284E;
}

@media (max-width: 767px) {
  .copyright {
    margin-bottom: 0.9375rem;
  }
}

.hero-robot {
  height: 44.5rem;
  width: 100%;
  position: relative;
  background: url("../img/images/main-img-1.jpg") no-repeat 50% 100%/cover;
}

@media (max-width: 767px) {
  .hero-robot {
    height: 25rem;
  }
}

@media (max-width: 480px) {
  .hero-robot {
    height: 16.0625rem;
  }
}

.hero-robot .btn2 {
  max-width: 13.5625rem;
  width: 100%;
}

.dialog-robot {
  list-style: none;
  width: 28.125rem;
  display: inline-block;
  position: absolute;
  bottom: 11.25rem;
  left: 12.0625rem;
  display: flex;
  flex-flow: column;
  padding-right: 3.125rem;
  overflow: hidden;
}

@media (max-width: 767px) {
  .dialog-robot {
    width: 13.75rem;
    bottom: 44%;
    left: 15%;
  }
}

@media (max-width: 480px) {
  .dialog-robot {
    left: 1.25rem;
  }
}

.dialog-robot.step-1 .dialog-robot__item-first {
  opacity: 1;
  position: relative;
}

.dialog-robot.step-2 .dialog-robot__item-first:after {
  display: none;
}

.dialog-robot.step-2 .dialog-robot__item-second {
  opacity: 1;
  position: relative;
}

.dialog-robot.step-3 .dialog-robot__item-second {
  opacity: 0;
  position: absolute;
}

.dialog-robot.step-3 .dialog-robot__item-third {
  opacity: 1;
  position: relative;
}

.dialog-robot__item {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 85%, rgba(255, 255, 255, 0.9) 85%, #fff 98%);
  border-radius: 6.25rem;
  min-height: 5.8125rem;
  min-width: 5.8125rem;
  margin-bottom: 1.0625rem;
  display: inline-flex;
  align-items: center;
  padding-left: 2.4375rem;
  padding-right: 1.5625rem;
  width: 100%;
  position: relative;
  opacity: 0;
  position: absolute;
  transition: all .2s ease;
}

@media (max-width: 767px) {
  .dialog-robot__item {
    min-height: 2.375rem;
    min-width: 2.375rem;
    padding-left: 0.75rem;
    padding-right: 0.9375rem;
    margin-bottom: 0.5rem;
  }
}

.dialog-robot__item:after {
  content: "";
  display: block;
  border: 1.125rem solid transparent;
  border-left: 1.875rem solid #fff;
  position: absolute;
  top: 50%;
  right: -2.75rem;
  transform: translate(0, -50%);
  z-index: 2;
}

@media (max-width: 767px) {
  .dialog-robot__item:after {
    display: none;
  }
}

.dialog-robot__item--ellipsis {
  padding: 0;
  height: 5.8125rem;
  justify-content: center;
  width: auto;
  align-self: flex-start;
  transition: none;
}

@media (max-width: 767px) {
  .dialog-robot__item--ellipsis {
    height: 2.375rem;
  }
}

.dialog-robot__item--ellipsis:after {
  display: none;
}

.dialog-robot__hello {
  font-family: "Futura PT";
  font-style: italic;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  color: #215AA7;
}

@media (max-width: 767px) {
  .dialog-robot__hello {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
}

.dialog-robot__ellipsis {
  font-family: Futura PT;
  font-style: italic;
  font-weight: bold;
  font-size: 1.5rem;
  color: #215AA7;
}

@media (max-width: 767px) {
  .dialog-robot__ellipsis {
    font-size: 0.625rem;
  }
}

.dialog-robot__ellipsis span {
  position: relative;
  top: -0.3125rem;
  animation: dialog-item-ellipsis 1s ease infinite;
  animation-fill-mode: forwards;
}

@media (max-width: 767px) {
  .dialog-robot__ellipsis span {
    top: -0.125rem;
  }
}

.dialog-robot__ellipsis span:nth-child(1) {
  animation-delay: 0s;
}

.dialog-robot__ellipsis span:nth-child(2) {
  animation-delay: .3s;
}

.dialog-robot__ellipsis span:nth-child(3) {
  animation-delay: .7s;
}

.dialog-robot__desc {
  font-family: "Futura PT";
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

@media (max-width: 767px) {
  .dialog-robot__desc {
    font-size: 0.5rem;
    line-height: 0.625rem;
  }
}

.hero-robot-animation {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 38.5rem;
  pointer-events: none;
}

@media (max-width: 767px) {
  .hero-robot-animation {
    width: 21.875rem;
  }
}

@media (max-width: 480px) {
  .hero-robot-animation {
    left: 70%;
    width: 17.5rem;
  }
}

@keyframes dialog-item-ellipsis {
  0% {
    color: #000000;
  }
  100% {
    color: #215AA7;
  }
}

.btn2 {
  padding: 0.6875rem 1rem;
  height: 4.625rem;
  display: inline-flex;
  align-items: center;
  background: #FDFEFF;
  border-radius: 62.5rem;
  align-self: center;
  transition: all .2s;
  flex-shrink: 0;
}

.btn2 svg line {
  stroke: #fff;
  transition: all .2s;
}

.btn2.posbc {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: auto;
  transform: translate(-50%, 50%);
  z-index: 2;
  max-width: none;
  width: auto;
}

.btn2:hover {
  text-decoration: none;
  background-color: #215AA7;
}

.btn2:hover .btn2__ico {
  background-color: #fff;
}

.btn2:hover .btn2__ico svg line {
  stroke: #215AA7;
}

.btn2:hover .btn2__ico:before {
  border: 0.125rem solid #215AA7;
}

.btn2:hover .btn2__ico:after {
  border: 0.125rem solid #215AA7;
}

.btn2:hover .btn2__desc {
  color: #fff;
}

.btn2__ico {
  margin-right: 1.3125rem;
  background: #215AA7;
  border-radius: 50%;
  width: 3.3125rem;
  height: 3.3125rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  flex-shrink: 0;
}

.btn2__ico:before {
  content: "";
  display: block;
  width: 1.1875rem;
  height: 1.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.125rem solid #FFFFFF;
  border-radius: 6.25rem;
  transition: all .2s;
}

.btn2__ico:after {
  content: "";
  display: block;
  width: 0rem;
  height: 0.1875rem;
  position: absolute;
  bottom: 1.125rem;
  left: 50%;
  transform: translate(-50%, 0%);
  border: 0.125rem solid #FFFFFF;
  border-radius: 6.25rem;
  transition: all .2s;
}

.btn2__ico-hover {
  display: none;
}

.btn2__desc {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #215AA7;
  transition: all .2s;
  flex-shrink: 0;
  padding-right: 1.875rem;
}

.about-razo {
  display: flex;
  background: radial-gradient(58.56rem at 45.72% 19.21%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
  padding-bottom: 5rem;
  position: relative;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .about-razo {
    flex-flow: column;
    justify-content: flex-start;
    background: radial-gradient(16.82688rem at 59.37% 7.5%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
    padding-bottom: 2.5rem;
  }
}

.about-razo__center {
  display: flex;
  flex-flow: column;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .about-razo__center {
    position: static;
    left: auto;
    top: auto;
    transform: none;
  }
}

.about-razo__left {
  min-height: 0.0625rem;
}

.about-razo__right {
  padding-right: 4.6875rem;
}

@media (max-width: 767px) {
  .about-razo__right {
    padding-right: 0;
  }
}

.about-razo__title {
  font-weight: bold;
  font-size: 4rem;
  line-height: 5.125rem;
  color: #215AA7;
  margin-left: 9.375rem;
  margin-top: 5.1875rem;
  margin-bottom: 1.5625rem;
}

@media (max-width: 767px) {
  .about-razo__title {
    margin-left: 1.25rem;
    font-size: 2.25rem;
    line-height: 2.875rem;
    margin-bottom: 0.3125rem;
    margin-top: 1.25rem;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .about-razo__title {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .about-razo__title-small {
    display: block;
    font-weight: 600;
    text-transform: none;
  }
}

@media (max-width: 767px) {
  .about-razo__title-value {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.about-razo .razo-img-wrap {
  margin-top: 7.5rem;
  position: relative;
  margin-left: -9.875rem;
}

@media (max-width: 767px) {
  .about-razo .razo-img-wrap {
    margin-left: 0;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
  }
}

.about-razo .razo-img-desc {
  position: absolute;
  right: 17.1875rem;
  top: -2.5rem;
}

@media (max-width: 767px) {
  .about-razo .razo-img-desc {
    left: 50%;
    right: auto;
  }
}

@media (max-width: 480px) {
  .about-razo .razo-img-desc {
    top: -1.875rem;
  }
}

.about-razo__razo-img {
  width: 43rem;
  max-width: none;
}

@media (max-width: 767px) {
  .about-razo__razo-img {
    width: 100%;
    top: 0;
    margin: 0 auto;
  }
}

.about-razo__razo-2-img-wrap {
  min-height: 1px;
}

.about-razo__razo-2-img {
  max-width: 30.625rem;
  width: 100%;
  position: absolute;
  left: 32.3125rem;
  bottom: 1.25rem;
}

@media (max-width: 767px) {
  .about-razo__razo-2-img {
    position: relative;
    width: 50%;
    margin-top: 1.25rem;
    margin-bottom: 0;
    margin-left: 0rem;
    left: auto;
    bottom: auto;
  }
}

.about-razo__robot-7 {
  width: 12.125rem;
  margin: 0 auto;
  margin-top: 6.875rem;
}

@media (max-width: 767px) {
  .about-razo__robot-7 {
    width: 23%;
    position: absolute;
    top: 5rem;
    left: 10%;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .about-razo__robot-7 {
    width: 29%;
    left: 9%;
    top: 8%;
  }
}

.about-razo__robot-8 {
  width: 17.3125rem;
  margin: 0 auto;
  margin-top: 6.75rem;
  position: absolute;
  right: 13.4375rem;
  top: 0;
}

@media (max-width: 767px) {
  .about-razo__robot-8 {
    width: 25%;
    right: 2.5rem;
  }
}

@media (max-width: 480px) {
  .about-razo__robot-8 {
    right: 1.25rem;
    margin-top: 2.5rem;
  }
}

.about-razo__btn {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  align-self: center;
  padding: 1.375rem 2.625rem;
}

@media (max-width: 480px) {
  .about-razo__btn {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    padding: 0.8125rem 2.5rem;
  }
}

.about-razo .properties-list {
  margin-top: 11.25rem;
}

@media (max-width: 767px) {
  .about-razo .properties-list {
    margin-top: 2.1875rem;
    margin-left: 0rem;
    padding: 0 1.25rem;
  }
}

.about-razo .recommendation {
  margin-top: 4.0625rem;
}

@media (max-width: 767px) {
  .about-razo .recommendation {
    margin: 0;
    margin-top: 4.0625rem;
  }
}

.recommendation {
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.9375rem 0;
  padding-left: 6.625rem;
  padding-right: 1.5625rem;
  background: #D9EEFE;
  border-radius: 3.5625rem;
  position: relative;
  max-width: 30rem;
  overflow: hidden;
}

@media (max-width: 767px) {
  .recommendation {
    margin-left: 0;
    max-width: 100%;
    margin-top: 2.5rem;
  }
}

@media (max-width: 480px) {
  .recommendation {
    font-size: 0.625rem;
    line-height: 0.8125rem;
    padding: 0.625rem 1.25rem;
    background: none;
  }
  .recommendation .chevron {
    display: none;
  }
}

.recommendation .chevron {
  position: absolute;
  top: -0.625rem;
  left: 3.25rem;
}

.recommendation h6 {
  font-weight: bold;
  margin-bottom: 0.625rem;
}

.recommendation p {
  font-size: 0.875rem;
  max-width: 21.875rem;
  width: 100%;
  color: #215AA7;
}

.razo-img-wrap {
  position: relative;
}

.razo-img-desc {
  font-style: italic;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0.5rem 1.4375rem;
  border-radius: 1.875rem;
  background: #2D8DD9;
  z-index: 3;
}

@media (max-width: 480px) {
  .razo-img-desc {
    padding: 0.25rem 0.75rem;
    font-size: 0.625rem;
    line-height: 0.8125rem;
  }
}

.properties-list {
  list-style: none;
  max-width: 30rem;
}

@media (max-width: 767px) {
  .properties-list {
    max-width: 100%;
  }
}

.properties-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1.625rem;
}

@media (max-width: 767px) {
  .properties-list li {
    margin-bottom: 0.9375rem;
  }
}

.properties-list__ico {
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 3.375rem;
  flex-shrink: 0;
  align-self: flex-start;
}

@media (max-width: 767px) {
  .properties-list__ico {
    width: 3.9375rem;
    height: 3.9375rem;
    margin-right: 1.3125rem;
  }
}

.properties-list__stomach-img {
  width: 3.4375rem;
}

@media (max-width: 767px) {
  .properties-list__stomach-img {
    width: 60%;
  }
}

.properties-list__clock-img {
  width: 3.375rem;
}

@media (max-width: 767px) {
  .properties-list__clock-img {
    width: 60%;
  }
}

.properties-list__timer-img {
  width: 3.8125rem;
}

@media (max-width: 767px) {
  .properties-list__timer-img {
    width: 65%;
  }
}

.properties-list__plate-img {
  width: 3rem;
}

@media (max-width: 767px) {
  .properties-list__plate-img {
    width: 60%;
  }
}

.properties-list__test-img {
  width: 3rem;
}

@media (max-width: 767px) {
  .properties-list__test-img {
    width: 60%;
  }
}

.properties-list__pills2-img {
  width: 3.1875rem;
}

@media (max-width: 767px) {
  .properties-list__pills2-img {
    width: 60%;
  }
}

.properties-list p {
  font-weight: 450;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 0.625rem;
}

@media (max-width: 767px) {
  .properties-list p {
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding-right: 0.3125rem;
  }
  .properties-list p br {
    display: none;
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

@media (max-width: 767px) {
  .articles {
    flex-flow: column;
  }
}

.articles .article-prev {
  width: 50%;
}

@media (max-width: 767px) {
  .articles .article-prev {
    width: 100%;
  }
}

.article-prev {
  min-height: 40.625rem;
  position: relative;
  padding-top: 7.375rem;
  padding-bottom: 5.3125rem;
  overflow: hidden;
}

@media (max-width: 767px) {
  .article-prev {
    padding-top: 2.125rem;
    padding-bottom: 1.5625rem;
    min-height: 20rem;
  }
}

.article-prev__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.article-prev__bg:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(15.46875rem at 50% 50%, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0) 100%);
}

.article-prev__bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  max-width: none;
  min-height: 100%;
}

@media (max-width: 767px) {
  .article-prev__bg-img {
    max-width: 100%;
  }
}

.article-prev__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 1.25rem;
  width: 100%;
  height: 100%;
}

.article-prev__ico {
  margin: 0rem auto;
  margin-bottom: 1.25rem;
  width: 4.875rem;
  height: 4.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 50%;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .article-prev__ico {
    width: 2.375rem;
    height: 2.375rem;
    margin-bottom: 0.8125rem;
  }
}

.article-prev__ico-img {
  width: 2.625rem;
}

@media (max-width: 767px) {
  .article-prev__ico-img {
    width: 60%;
  }
}

.article-prev__title {
  font-weight: bold;
  font-size: 2.875rem;
  line-height: 3.125rem;
  text-align: center;
  color: #215AA7;
  margin-top: 2.8125rem;
}

@media (max-width: 767px) {
  .article-prev__title {
    margin-top: 0.8125rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.article-prev__title span {
  display: block;
}

.article-prev__title span:first-child {
  position: relative;
  margin-bottom: 2.8125rem;
}

@media (max-width: 767px) {
  .article-prev__title span:first-child {
    margin-bottom: 1.25rem;
  }
}

.article-prev__title span:first-child:after {
  content: "";
  display: block;
  width: 1.5625rem;
  border-radius: 3.125rem;
  border: 0.125rem solid #215AA7;
  box-sizing: border-box;
  position: absolute;
  bottom: -1.8125rem;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .article-prev__title span:first-child:after {
    bottom: -1.125rem;
    border: 0.0625rem solid #215AA7;
  }
}

.article-prev__title span:last-child {
  font-weight: 900;
  font-size: 1.625rem;
  line-height: 2.5rem;
  color: #153E75;
}

@media (max-width: 767px) {
  .article-prev__title span:last-child {
    font-size: 0.8125rem;
    line-height: 0.875rem;
    margin-top: 2rem;
  }
}

.article-prev__btn {
  margin: 0 auto;
  margin-top: 3.75rem;
  min-width: 8.125rem;
}

@media (max-width: 767px) {
  .article-prev__btn {
    margin-top: 2.5rem;
    font-size: 0.75rem !important;
    height: 2.75rem !important;
  }
}

.chevron-wrap {
  width: 3.9375rem;
  height: 3.9375rem;
  border-radius: 50%;
  background: #D8EEFE;
  overflow: hidden;
  position: relative;
}

.chevron-wrap .chevron {
  position: absolute;
  top: -1.25rem !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  transition: top .2s;
}

.chevron {
  width: 1.625rem;
  height: 2.8125rem;
}

.chevron::before {
  content: "";
  position: absolute;
  bottom: -0.875rem;
  margin-top: 0;
  border-width: 0.1875rem 0.8125rem 0.8125rem 0.8125rem;
  border-style: solid;
  border-color: #215AA7  #215AA7 transparent #215AA7;
  left: 0;
}

.chevron::after {
  content: "";
  position: absolute;
  background: #215AA7;
  width: 1.625rem;
  height: 2.8125rem;
  display: block;
  position: absolute;
}

.literature-wrap {
  margin-top: 6.875rem;
}

@media (max-width: 767px) {
  .literature-wrap {
    margin-top: 3.125rem;
  }
}

.literature-margin {
  margin: 3.125rem 0;
  margin-bottom: 6.25rem;
}

@media (max-width: 767px) {
  .literature-margin {
    margin: 1.25rem 0;
  }
}

.literature {
  background: #F7FBFF;
  position: relative;
}

.literature .container {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .literature .container {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
    justify-content: flex-start;
  }
}

.literature .chevron-wrap {
  position: absolute;
  top: 0.875rem;
  left: 2.875rem;
}

@media (max-width: 767px) {
  .literature .chevron-wrap {
    left: 0.9375rem;
    top: 1.5625rem;
  }
}

.literature .chevron {
  position: absolute;
  left: 3.4375rem;
  top: 0rem;
}

@media (max-width: 767px) {
  .literature .chevron {
    left: 1.25rem;
  }
}

.literature__link {
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #215AA7;
  border-bottom: 0.125rem dashed #C1DDF1;
  display: inline-block;
  margin-left: 3.75rem;
}

@media (max-width: 480px) {
  .literature__link {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-left: 2.8125rem;
  }
}

.literature__link:hover {
  text-decoration: none;
  border-bottom: 0.125rem solid #C1DDF1;
}

.literature__btn {
  padding: 0.8125rem 0;
  width: 7.6875rem;
  background: #D8EEFE;
  color: #215AA7;
  margin-right: 0.3125rem;
  border: none;
}

.literature__btn:hover {
  border: none;
  background: #215AA7 !important;
  color: #fff;
}

.literature.about-product {
  overflow: hidden;
}

@media (max-width: 767px) {
  .literature.about-product {
    margin-top: 1.5625rem;
  }
}

.literature.about-product.open .chevron {
  top: -0.3125rem !important;
}

.literature.about-product .chevron-wrap {
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 767px) {
  .literature.about-product .chevron-wrap {
    left: 0.9375rem;
  }
}

.literature.about-product .literature__link {
  border-bottom: none;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-left: 6.875rem;
}

@media (max-width: 767px) {
  .literature.about-product .literature__link {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin-left: 5.625rem;
  }
}

.literature.about-product .container {
  padding-right: 1.875rem;
}

@media (max-width: 767px) {
  .literature.about-product .container {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
}

.literature.about-product .literature__btn {
  margin-right: 0;
  background: #D8EEFE;
  height: 3.75rem;
  width: 9.375rem;
}

.literature.about-product:hover {
  background: none;
  border: 0.125rem dashed #C1DDF1;
}

.bibliography__cover {
  padding: 0 3.4375rem;
  background-color: #fff;
  overflow: hidden;
  height: 0;
  transition: height .3s ease;
}

@media (max-width: 767px) {
  .bibliography__cover {
    padding: 0 1.25rem;
  }
}

.bibliography__list {
  list-style: none;
}

.bibliography__list li {
  padding: 1.5625rem 3.75rem;
  display: flex;
  border-bottom: 0.25rem solid #F7FBFF;
  min-height: 5.625rem;
}

@media (max-width: 767px) {
  .bibliography__list li {
    padding: 0.625rem 0rem;
    min-height: auto;
  }
}

.bibliography__list li:last-child {
  border-bottom: none;
}

.bibliography__list li .num {
  display: block;
  width: 1.3125rem;
  height: 1.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #153E75;
  font-weight: 500;
  font-size: 0.875rem;
  color: #FFFFFF;
  flex-shrink: 0;
  margin-right: 2.5rem;
  margin-top: 0.1875rem;
}

@media (max-width: 767px) {
  .bibliography__list li .num {
    margin-right: 1.25rem;
    width: 0.9375rem;
    height: 0.9375rem;
    font-size: 0.5625rem;
  }
}

.bibliography__list li p {
  font-weight: 450;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  max-width: 70.9375rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .bibliography__list li p {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
}

.enough-razo {
  background: url("../img/images/img-6.jpg") no-repeat 50%/cover, linear-gradient(180deg, rgba(255, 255, 255, 0) 38.75%, #FFFFFF 100%), #F6F6F6;
  position: relative;
  min-height: 1px;
}

@media (max-width: 767px) {
  .enough-razo {
    background-position: 0 0;
    background-size: contain;
    padding-bottom: 2.125rem;
  }
}

@media (max-width: 480px) {
  .enough-razo {
    background-size: 29.375rem;
  }
}

.enough-razo .razo-img-wrap {
  margin-left: -10.9375rem;
}

@media (max-width: 767px) {
  .enough-razo .razo-img-wrap {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .enough-razo .razo-img-wrap {
    margin-top: 3.4375rem;
  }
}

.enough-razo .razo-img-desc {
  right: 27.1875rem;
}

@media (max-width: 767px) {
  .enough-razo .razo-img-desc {
    right: auto;
    left: 2.5rem;
  }
}

@media (max-width: 480px) {
  .enough-razo .razo-img-desc {
    left: 1.25rem;
  }
}

.enough-razo .about-razo__title {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.875rem;
  text-transform: uppercase;
  margin-right: -3.75rem;
  margin-bottom: 0.625rem;
  margin-left: 8.125rem;
  margin-top: 8rem;
}

@media (max-width: 767px) {
  .enough-razo .about-razo__title {
    margin: 0;
    margin-top: 2.1875rem;
    margin-left: 2.5rem;
    text-align: left;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
}

@media (max-width: 480px) {
  .enough-razo .about-razo__title {
    margin-top: 2.1875rem;
    margin-left: 1.25rem;
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.enough-razo .about-razo__robot-7 {
  margin-top: 12.5rem;
}

@media (max-width: 767px) {
  .enough-razo .about-razo__robot-7 {
    margin-top: 2.5rem;
    left: auto;
    right: 2.5rem;
    top: 0;
  }
}

@media (max-width: 480px) {
  .enough-razo .about-razo__robot-7 {
    width: 5.375rem;
    right: 1.5625rem;
  }
}

.enough-razo .about-razo__desc {
  margin-top: 0;
  margin-left: 8.125rem;
}

@media (max-width: 767px) {
  .enough-razo .about-razo__desc {
    display: none;
  }
}

.enough-razo .about-razo .recommendation {
  margin-left: 0;
}

@media (max-width: 767px) {
  .enough-razo .about-razo__razo-img {
    width: 80%;
    margin-left: -20%;
  }
}

@media (max-width: 480px) {
  .enough-razo .about-razo__razo-img {
    width: 70%;
    margin-left: -17%;
  }
}

.enough-razo .properties-list {
  margin-left: 0;
  margin-top: 8.75rem;
}

@media (max-width: 767px) {
  .enough-razo .properties-list {
    margin-top: 0;
  }
}

.enough-razo .btn2 {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  z-index: 2;
}

@media (max-width: 767px) {
  .enough-razo .btn2 {
    display: none;
  }
}

.article-video-medical {
  margin-top: 7.5rem;
}

.medical-razo {
  flex-flow: column;
  background: url("../img/images/img-9.jpg") no-repeat 50%/cover, linear-gradient(180deg, rgba(255, 255, 255, 0) 38.75%, #FFFFFF 100%), #F6F6F6;
  padding-bottom: 1.875rem;
  min-height: 1px;
}

.medical-razo .about-razo__title {
  margin-left: 8.125rem;
}

@media (max-width: 767px) {
  .medical-razo .about-razo__title {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .medical-razo .about-razo__title {
    margin-left: 1.25rem;
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.medical-razo .properties-list {
  margin-left: 8.125rem;
  margin-top: 1.25rem;
}

@media (max-width: 767px) {
  .medical-razo .properties-list {
    margin-left: 0;
  }
}

.medical-razo .about-razo__title {
  text-transform: uppercase;
}

.pass-test-wrap {
  min-height: 1px;
}

.pass-test {
  background: radial-gradient(43.23rem at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
  padding-top: 3.75rem;
  display: flex;
  flex-flow: column;
  padding-bottom: 3.875rem;
  position: relative;
}

.pass-test__title {
  margin-top: 3.75rem;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.875rem;
  text-align: center;
  text-transform: uppercase;
  color: #215AA7;
  margin-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .pass-test__title {
    margin-top: 0.9375rem;
    font-size: 2.0625rem;
    line-height: 2.625rem;
    margin-bottom: 0.4375rem;
  }
}

.pass-test__desc {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  max-width: 31.875rem;
  margin: 0 auto;
  color: #153E75;
  margin-bottom: 2.8125rem;
}

@media (max-width: 767px) {
  .pass-test__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 480px) {
  .pass-test__desc br {
    display: none;
  }
}

.pass-test__btn {
  display: flex;
  padding: 1.375rem 4.375rem;
  max-width: 14.375rem;
  margin: 3.125rem auto;
}

@media (max-width: 767px) {
  .pass-test__btn {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .pass-test__btn {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    padding: 1.1875rem 3.125rem;
    max-width: 12.5rem;
  }
}

.pass-test__robot-img {
  max-width: 25.3125rem;
  width: 100%;
  position: absolute;
  left: 5rem;
  top: 3.75rem;
  align-self: flex-start;
}

@media (max-width: 767px) {
  .pass-test__robot-img {
    max-width: 50%;
    position: static;
    margin: 0 auto;
    left: auto;
    top: auto;
  }
}

@media (max-width: 480px) {
  .pass-test__robot-img {
    max-width: 63%;
    width: 100%;
  }
}

.robot-speak {
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  border-radius: 6.25rem;
  position: relative;
  padding: 2.8125rem;
  max-width: 40.625rem;
  text-align: center;
  margin: 0 auto;
  font-style: italic;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  text-transform: uppercase;
  color: #215AA7;
  position: relative;
}

@media (max-width: 767px) {
  .robot-speak {
    padding: 1.5625rem 1.875rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    position: absolute;
    bottom: 16%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 95%;
    max-width: 25rem;
  }
}

.robot-speak:before {
  content: "";
  display: block;
  border: 2.4375rem solid transparent;
  border-top: 1.5625rem solid #fff;
  position: absolute;
  left: -1.6875rem;
  top: 4.625rem;
  transform: rotate(-5deg);
}

@media (max-width: 767px) {
  .robot-speak:before {
    left: 57%;
    top: -0.4375rem;
    transform: rotate(17deg);
  }
}

.robot-speak__small {
  font-style: italic;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 2.1875rem;
  color: #153E75;
  text-transform: none;
}

@media (max-width: 767px) {
  .robot-speak__small {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

@media (max-width: 480px) {
  .robot-speak__small {
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0 1.5625rem;
  }
}

.section-title {
  color: #215AA7;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.875rem;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .section-title {
    font-size: 1.875rem;
    line-height: 2.375rem;
  }
  .section-title br {
    display: none;
  }
}

.instruction {
  padding-top: 7.1875rem;
  background: #fff;
  padding-bottom: 3.75rem;
}

@media (max-width: 767px) {
  .instruction {
    padding-top: 3.4375rem;
    padding-bottom: 1.25rem;
  }
}

.instruction .container-2 {
  display: flex;
  flex-flow: column;
}

.instruction__head {
  display: flex;
  margin-bottom: 6.25rem;
}

@media (max-width: 767px) {
  .instruction__head {
    flex-flow: column;
    margin-bottom: 3.125rem;
  }
}

.instruction .section-title {
  width: 50%;
}

@media (max-width: 767px) {
  .instruction .section-title {
    width: 100%;
    margin-bottom: 1.875rem;
  }
}

.instruction__specifications {
  width: 50%;
}

@media (max-width: 767px) {
  .instruction__specifications {
    width: 100%;
  }
}

.instruction__specifications p {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .instruction__specifications p {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}

.instruction__specifications p b {
  font-weight: 600;
}

.about-product {
  background: #FFFFFF;
  border: 0.125rem dashed #C1DDF1;
  box-sizing: border-box;
  border-radius: 3.125rem;
  margin-bottom: 1.9375rem;
  transition: all .5s;
}

@media (max-width: 767px) {
  .about-product {
    margin-bottom: 0.9375rem;
    border-radius: 2rem;
  }
}

@media (max-width: 480px) {
  .about-product {
    margin-bottom: 0.625rem;
    border-radius: 1.5625rem;
  }
}

.about-product:hover {
  border: 0.125rem dashed #215AA7;
  background: #215AA7;
}

.about-product:hover .about-product__head p {
  color: #FFFFFF;
}

.about-product.open {
  background: #FFFFFF;
  border: 0.125rem dashed #C1DDF1;
}

.about-product.open .about-product__head {
  background: #215AA7;
  border-radius: 3.125rem 3.125rem 0 0;
  margin: -0.125rem;
  padding: 1.375rem 2.875rem;
}

@media (max-width: 767px) {
  .about-product.open .about-product__head {
    border-radius: 1.5625rem 1.5625rem 0 0;
    padding: 0.625rem 0.8125rem;
  }
}

.about-product.open .about-product__head p {
  color: #fff;
}

.about-product__head {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.25rem 2.75rem;
  padding-right: 1.875rem;
}

@media (max-width: 767px) {
  .about-product__head {
    padding: 0.5rem 0.6875rem;
  }
}

.about-product__head span {
  width: 3.9375rem;
  height: 3.9375rem;
  background: #D8EEFE;
  display: block;
  border-radius: 50%;
  font-family: 'Helvetica Neue';
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  text-align: center;
  text-transform: uppercase;
  color: #215AA7;
  margin-right: 3.5625rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .about-product__head span {
    margin-right: 1.0625rem;
    width: 2.125rem;
    height: 2.125rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}

.about-product__head p {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #153E75;
}

@media (max-width: 767px) {
  .about-product__head p {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.about-product__text {
  padding: 3.125rem 10.3125rem;
  padding-top: 1.5625rem;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

@media (max-width: 767px) {
  .about-product__text {
    padding: 1.25rem 2.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.about-product__spoiler {
  overflow: hidden;
  height: 0;
  transition: all .3s ease;
}

.about-product.research {
  margin-top: 3.125rem;
}

@media (max-width: 767px) {
  .about-product.research {
    margin-top: 1.25rem;
  }
}

.about-product.research .about-product__head span {
  background-color: #215AA7 !important;
}

.about-product.research .open-book {
  width: 1.6875rem;
}

@media (max-width: 767px) {
  .about-product.research .open-book {
    width: 60%;
  }
}

.tell-you-wrap {
  margin-top: 4.5625rem;
  padding: 5.625rem 0;
  padding-top: 0;
  padding-bottom: 5.9375rem;
}

@media (max-width: 767px) {
  .tell-you-wrap {
    padding: 0;
    padding-top: 2.5rem;
    margin-top: 3.125rem;
    padding-bottom: 1.25rem;
  }
}

.tell-you-wrap.type-cyan {
  background: #215AA7;
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you {
    min-height: 16.6875rem;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you__robot-7 {
    left: 15rem;
    bottom: 0rem;
    top: auto;
    width: 8.9375rem;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you__razo-img {
    width: 30rem;
    right: 9.375rem;
    top: 2.5rem;
    left: auto;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you__robot-say {
    left: 36%;
    right: auto;
    top: 3.875rem;
    background-color: #fff;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you__robot-say:before {
    left: -3.75rem;
    top: 3.8125rem;
    background-color: #fff;
  }
}

@media (min-width: 768px) {
  .tell-you-wrap.type-cyan .tell-you__robot-say:after {
    left: -5.9375rem;
    top: 1.875rem;
    background-color: #fff;
  }
}

.tell-you {
  max-width: 45.625rem;
  width: 100%;
  min-height: 19.375rem;
  position: relative;
  margin: 0 auto;
}

.tell-you__robot-7 {
  width: 8.75rem;
  position: absolute;
  left: 0;
  top: -1.25rem;
}

@media (max-width: 767px) {
  .tell-you__robot-7 {
    left: 1.25rem;
    min-height: 7.1875rem;
    top: 6.875rem;
    width: 5rem;
    z-index: 3;
  }
}

.tell-you__razo-img {
  width: 24.5rem;
  position: absolute;
  left: 12%;
  bottom: 0;
}

@media (max-width: 767px) {
  .tell-you__razo-img {
    left: auto;
    right: 1.25rem;
    width: 15.625rem;
    bottom: 1.25rem;
  }
}

.tell-you__robot-say {
  position: absolute;
  right: 0;
  top: 0rem;
  background: #F2FAFF;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.375rem 2.125rem;
  padding-left: 3.4375rem;
  z-index: 2;
}

@media (max-width: 767px) {
  .tell-you__robot-say {
    position: relative;
    width: 90%;
    max-width: 31.25rem;
    margin: 0 auto;
    right: auto;
    top: auto;
    z-index: 2;
    flex-flow: column;
    padding: 1.25rem 0.9375rem;
  }
}

.tell-you__robot-say:before, .tell-you__robot-say:after {
  content: "";
  display: block;
  border-radius: 50%;
  background: #F2F9FF;
  position: absolute;
}

.tell-you__robot-say:before {
  width: 2.1875rem;
  height: 2.1875rem;
  left: -3.125rem;
  top: 0.625rem;
}

@media (max-width: 767px) {
  .tell-you__robot-say:before {
    left: 50%;
    top: auto;
    bottom: -4.375rem;
    width: 1.1875rem;
    height: 1.1875rem;
  }
}

.tell-you__robot-say:after {
  width: 1.3125rem;
  height: 1.3125rem;
  left: -5rem;
  top: 3.75rem;
}

@media (max-width: 767px) {
  .tell-you__robot-say:after {
    left: 40%;
    top: auto;
    width: 0.75rem;
    height: 0.75rem;
    bottom: -2.5rem;
  }
}

.tell-you__desc {
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin-right: 3.125rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .tell-you__desc {
    margin-right: 0;
    margin-bottom: 0.9375rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .tell-you__desc br {
    display: none;
  }
}

.tell-you__btn {
  padding: 1.375rem 2.5rem;
  font-size: 1rem;
  line-height: 1.1875rem;
}

@media (max-width: 480px) {
  .tell-you__btn {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    padding: 1rem 2.5rem;
  }
}

.question {
  padding-top: 5rem;
  background-color: #fff;
  padding-bottom: 5.625rem;
}

@media (max-width: 767px) {
  .question {
    padding-top: 1.25rem;
    padding-bottom: 1.875rem;
  }
}

.question__cover {
  background: #F6FBFF;
  border-radius: 3.125rem;
  padding: 2.5rem 5.3125rem;
  padding-bottom: 8.4375rem;
  position: relative;
}

@media (max-width: 767px) {
  .question__cover {
    padding: 1.875rem 0rem;
    padding-bottom: 4.375rem;
    border-radius: 1.5625rem;
    background: none;
  }
}

.question__content {
  position: relative;
  z-index: 3;
}

.question__title {
  margin-bottom: 1.3125rem;
  margin-top: 3.75rem;
}

@media (max-width: 767px) {
  .question__title {
    margin-top: 1.875rem;
    font-size: 1.375rem;
    margin-bottom: 0.625rem;
    text-align: center;
  }
}

.question__desc {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
  margin-bottom: 4.0625rem;
}

@media (max-width: 767px) {
  .question__desc {
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    text-align: center;
  }
}

.question__robot-img {
  width: 18.3125rem;
  position: absolute;
  top: -2.5rem;
  right: 8.75rem;
}

@media (max-width: 767px) {
  .question__robot-img {
    display: none;
  }
}

.question-form {
  display: flex;
}

@media (max-width: 767px) {
  .question-form {
    flex-flow: column;
  }
}

.question-form__col {
  position: relative;
  width: 100%;
}

.question-form__col:first-child {
  padding-right: 1.4375rem;
  width: 21.875rem;
  flex-shrink: 0;
  z-index: 1;
}

@media (max-width: 767px) {
  .question-form__col:first-child {
    box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
    width: 100%;
    padding-right: 0;
    border-radius: 3.125rem;
    margin-bottom: 1.25rem;
  }
}

.question-form__col:last-child {
  z-index: 2;
}

@media (max-width: 767px) {
  .question-form__col:last-child {
    box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
    border-radius: 3.125rem;
    padding: 0.9375rem 1.25rem;
    padding-top: 1.875rem;
  }
}

.question-form__col:last-child input {
  padding-right: 17.3125rem;
}

@media (max-width: 767px) {
  .question-form__col:last-child input {
    margin-bottom: 1.875rem;
    padding-right: 1.875rem;
  }
}

.question-form input {
  height: 5rem;
  padding: 0 2.625rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 6.25rem;
  width: 100%;
  outline: none;
  border: none;
  display: block;
  font-size: 1.3125rem;
  font-family: "Futura PT";
  color: #0C284E;
}

@media (max-width: 767px) {
  .question-form input {
    width: 100%;
    height: 3.75rem;
    padding: 0 1.875rem;
    box-shadow: none;
    font-size: 0.75rem;
    margin-bottom: 0;
    text-align: center;
  }
}

.question-form input::placeholder {
  opacity: 1;
  color: #000;
  font-size: 1.3125rem;
  line-height: 2.125rem;
  font-family: "Futura PT";
  color: #0C284E;
}

@media (max-width: 767px) {
  .question-form input::placeholder {
    font-size: 0.75rem;
  }
}

.question-form button {
  position: absolute;
  top: 50%;
  right: 1rem;
  padding: 0 3.125rem;
  height: 3.625rem;
  border: none;
  cursor: pointer;
  outline: none;
  transform: translate(0, -50%);
  width: 14.875rem;
  display: flex;
}

@media (max-width: 767px) {
  .question-form button {
    position: static;
    transform: translate(0);
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .question-form button {
    width: 100%;
  }
}

.politics {
  padding-bottom: 21.875rem;
}

@media (max-width: 767px) {
  .politics {
    padding-bottom: 5rem;
  }
}

.politics__title {
  margin-bottom: 4.375rem;
}

.politics__h5 {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 4.375rem 0;
}

@media (max-width: 767px) {
  .politics__h5 {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin: 1.25rem 0;
  }
}

.politics__p {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  margin: 2.1875rem 0;
  max-width: 71rem;
}

@media (max-width: 767px) {
  .politics__p {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}

.not-found {
  padding-top: 2.8125rem;
  padding-bottom: 4.0625rem;
  min-height: 1px;
}

.not-found__title {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.125rem;
  margin-top: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  color: #215AA7;
  margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
  .not-found__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.not-found__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  text-align: center;
  color: #0C284E;
  margin-bottom: 0.3125rem;
}

@media (max-width: 767px) {
  .not-found__desc {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.not-found__desc a {
  color: #215AA7;
  font-weight: 900;
}

.not-found__img {
  max-width: 48.5625rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  left: 3.125rem;
}

@media (max-width: 767px) {
  .not-found__img {
    left: auto;
    width: 80%;
  }
}

.test {
  padding-bottom: 7.5rem;
  background: radial-gradient(43.23rem at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
  padding-top: 1.25rem;
  position: relative;
  display: none;
}

@media (max-width: 767px) {
  .test {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.test.visible {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transition: all .2s ease;
}

.test__wrap {
  display: flex;
  justify-content: space-between;
  padding-left: 8rem;
}

@media (max-width: 767px) {
  .test__wrap {
    flex-flow: column;
    padding-left: 0;
  }
}

.test__wrap-rezult {
  justify-content: center;
  padding-left: 0;
  align-items: center;
}

.test__wrap-rezult .test__left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8rem;
}

.test__wrap-rezult .test__right {
  width: auto;
}

.test__wrap-rezult .test__right:after {
  display: none;
}

.test__title {
  color: #215AA7;
  margin-top: 5.9375rem;
  margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
  .test__title {
    background: #215AA7;
    padding: 1.375rem 0.625rem;
    padding-bottom: 1.25rem;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .test__title {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.test__title-small {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.1875rem;
  color: #153E75;
  text-transform: none;
}

@media (max-width: 767px) {
  .test__title-small {
    padding: 0.25rem 0.625rem;
    padding-bottom: 0.5rem;
    text-align: center;
    background: #0C284E;
    color: #FFFFFF;
  }
  .test__title-small br {
    display: none;
  }
}

@media (max-width: 767px) {
  .test__title-small {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.test__desc {
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #95B6E2;
  margin-top: 8.9375rem;
  max-width: 22.5rem;
}

@media (max-width: 767px) {
  .test__desc {
    margin: 0;
    display: none;
  }
}

.test__right {
  position: relative;
  width: 70%;
  margin-top: 5rem;
}

@media (max-width: 767px) {
  .test__right {
    width: 100%;
    margin-top: 0;
  }
}

.test__right:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  height: 8.5625rem;
  width: 91%;
  max-width: 59.375rem;
  top: 0;
  background: linear-gradient(180deg, #F8FCFF 3.12%, #F6FBFF 32.81%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
}

@media (max-width: 767px) {
  .test__right:after {
    display: none;
  }
}

.test__scroll {
  overflow: auto;
  max-height: 32.5rem;
  overflow-x: hidden;
}

.test__cover {
  padding-top: 6.25rem;
  padding-right: 4.6875rem;
  width: 100%;
  padding-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .test__cover {
    padding: 0;
    padding-right: 1.25rem;
    padding-top: 1.875rem;
  }
}

.test__robot-img {
  width: 22.5625rem;
}

@media (max-width: 767px) {
  .test__robot-img {
    display: none;
  }
}

.test-answer {
  display: flex;
  margin-bottom: 2.8125rem;
  max-width: 55.625rem;
}

.test-answer.answered {
  pointer-events: none;
}

.test-answer.answered .test-answer__btn {
  background: #F3FAFF;
  color: #95B6E2;
}

@media (max-width: 767px) {
  .test-answer {
    flex-flow: column;
    padding: 0 1.25rem;
    margin-bottom: 2.0625rem;
  }
}

.test-answer__content {
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  border-radius: 6.25rem;
  position: relative;
  display: flex;
  padding: 0.9375rem 0;
}

@media (max-width: 767px) {
  .test-answer__content {
    background: #FFFFFF;
    box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
    border-radius: 0.75rem;
  }
}

@media (max-width: 480px) {
  .test-answer__content {
    width: 100%;
  }
}

.test-answer__content:before {
  content: "";
  display: block;
  position: absolute;
}

.test-answer__col {
  position: relative;
  z-index: 5;
}

.test-answer__participant-img {
  margin-top: 1.25rem;
  width: 5.3125rem;
  height: 5.3125rem;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .test-answer__participant-img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    margin-bottom: 1.25rem;
  }
}

.test-answer__participant-img img {
  width: 100%;
}

.test-answer__info {
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
  margin-bottom: 0.4375rem;
}

@media (max-width: 480px) {
  .test-answer__info {
    font-size: 0.625rem;
    line-height: 0.8125rem;
  }
}

.test-answer__text {
  font-family: 'Futura PT';
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  margin-bottom: 0.75rem;
  min-height: 3.125rem;
  max-width: 22.5rem;
}

@media (max-width: 480px) {
  .test-answer__text {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.test-answer__btns {
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
}

@media (max-width: 767px) {
  .test-answer__btns {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .test-answer__btns {
    justify-content: space-between;
    margin: 0;
  }
}

.test-answer__btn {
  width: 6.4375rem;
  height: 3.625rem;
  background: #215AA7;
  border-radius: 6.25rem;
  margin-left: 1.5625rem;
}

.test-answer__btn.selected {
  color: #fff !important;
  background: #215AA7 !important;
}

@media (max-width: 767px) {
  .test-answer__btn {
    margin-left: 0;
    margin-right: 1.5625rem;
    height: 3.125rem;
  }
}

@media (max-width: 480px) {
  .test-answer__btn {
    margin: 0;
    height: 2.375rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: bold;
  }
}

.test-answer__btn-result {
  width: 10.625rem;
  height: 3.625rem;
  margin-left: 1.875rem;
}

@media (max-width: 767px) {
  .test-answer__btn-result {
    margin-left: 0;
  }
}

.test-answer__prints {
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 3.125rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  color: #95B6E2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.125rem;
}

@media (max-width: 767px) {
  .test-answer__prints {
    margin-top: 0.75rem;
  }
}

.test-answer__prints i {
  width: 0.1875rem;
  height: 0.1875rem;
  background: #95B6E2;
  border-radius: 0.1875rem;
  margin: 0 0.0625rem;
  animation: test-answer-ellipsis 1s ease infinite;
  animation-fill-mode: forwards;
}

.test-answer__prints i:nth-child(1) {
  animation-delay: 0s;
}

.test-answer__prints i:nth-child(2) {
  animation-delay: .3s;
}

.test-answer__prints i:nth-child(3) {
  animation-delay: .7s;
}

.test-answer__col-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 0.75rem;
}

@media (max-width: 767px) {
  .test-answer__col-img {
    margin-left: 0;
    margin-right: 0.75rem;
  }
}

.test-answer__img-zzz {
  width: 3.375rem;
}

@media (max-width: 480px) {
  .test-answer__img-zzz {
    width: 2rem;
  }
}

.test-answer.robot-answer {
  justify-content: flex-start;
}

.test-answer.robot-answer .test-answer__participant-img {
  margin-right: 4.0625rem;
  background: url("../img/icons/test-robot.png") no-repeat 50%/contain;
}

@media (max-width: 767px) {
  .test-answer.robot-answer .test-answer__participant-img {
    margin-left: 1.25rem;
    margin-bottom: 0.625rem;
    margin-top: 0;
  }
}

.test-answer.robot-answer .test-answer__content {
  padding-left: 4.0625rem;
  padding-right: 1.875rem;
  background: #FFFFFF;
}

@media (max-width: 767px) {
  .test-answer.robot-answer .test-answer__content {
    padding: 1.125rem;
    flex-flow: column;
  }
}

.test-answer.robot-answer .test-answer__content:before {
  border: 2.4375rem solid transparent;
  border-top: 2.125rem solid #fff;
  left: -1.375rem;
  top: 1.375rem;
  transform: rotate(-24deg);
}

@media (max-width: 767px) {
  .test-answer.robot-answer .test-answer__content:before {
    left: 27%;
    top: -0.8125rem;
    transform: rotate(29deg);
  }
}

.test-answer.robot-answer .test-answer__info {
  color: #A7BFD2;
}

@media (max-width: 767px) {
  .test-answer.robot-answer.test-print {
    flex-flow: row;
  }
}

.test-answer.robot-answer.test-print .test-answer__participant-img {
  margin-right: 3.75rem;
}

@media (max-width: 767px) {
  .test-answer.robot-answer.test-print .test-answer__participant-img {
    margin-right: 1.25rem;
  }
}

.test-answer.user-answer {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .test-answer.user-answer {
    flex-flow: column-reverse;
    align-items: flex-end;
  }
}

.test-answer.user-answer .test-answer__participant-img {
  margin-left: 4.0625rem;
  margin-right: 1.875rem;
  background: url("../img/icons/test-user.png") no-repeat 50%/contain;
}

@media (max-width: 767px) {
  .test-answer.user-answer .test-answer__participant-img {
    margin-right: 1.25rem;
    margin-bottom: 0.625rem;
    margin-top: 0;
  }
}

.test-answer.user-answer .test-answer__content {
  padding: 0.9375rem 0;
  background: #215AA7;
  padding-left: 4.375rem;
  padding-right: 3.125rem;
}

@media (max-width: 767px) {
  .test-answer.user-answer .test-answer__content {
    padding: 1.25rem;
    flex-flow: row-reverse;
  }
}

.test-answer.user-answer .test-answer__content:before {
  border: 3.75rem solid transparent;
  border-bottom: 2.125rem solid #215AA7;
  right: -1.875rem;
  top: -2.6875rem;
  transform: rotate(-4deg);
}

@media (max-width: 767px) {
  .test-answer.user-answer .test-answer__content:before {
    right: 42%;
    top: -2.6875rem;
    transform: rotate(-58deg);
  }
}

.test-answer.user-answer .test-answer__info {
  color: #3870BB;
}

.test-answer.user-answer .test-answer__text {
  color: #FFFFFF;
  max-width: 22.5rem;
}

.test-answer.user-answer.test-print {
  flex-flow: row-reverse;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .test-answer.user-answer.test-print {
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.test-answer.user-answer.test-print .test-answer__participant-img {
  margin-left: 2.3125rem;
}

@media (max-width: 767px) {
  .test-answer.user-answer.test-print .test-answer__participant-img {
    margin-left: 1.25rem;
  }
}

@keyframes test-answer-ellipsis {
  0% {
    background: #215AA7;
  }
  100% {
    background: #95B6E2;
  }
}

.test-end-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  text-transform: uppercase;
  color: #EFF8FF;
  background: #C1DDF1;
  border-radius: 0;
}

@media (max-width: 480px) {
  .test-end-btn {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}

.test-result {
  padding: 2.8125rem 4.5625rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 3.125rem;
  max-width: 51.0625rem;
  margin: 1.25rem;
}

@media (max-width: 767px) {
  .test-result {
    padding: 1.25rem;
    border-radius: 1.25rem;
  }
}

.test-result__title {
  font-weight: 900;
  padding-bottom: 1.4375rem;
  border-bottom: 0.1875rem solid #D9EEFE;
  margin-bottom: 2.1875rem;
}

@media (max-width: 767px) {
  .test-result__title {
    margin: 1.125rem;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    padding-bottom: 0.9375rem;
  }
}

.test-result__desc {
  margin: 2.375rem 0;
  font-size: 1.3125rem;
  line-height: 2.125rem;
}

@media (max-width: 767px) {
  .test-result__desc {
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    margin: 0.9375rem;
  }
}

.test-result__btn {
  padding: 0 2.25rem;
  height: 3.625rem;
  margin-top: 3.625rem;
  margin-bottom: 1.875rem;
}

@media (max-width: 767px) {
  .test-result__btn {
    margin-top: 1.25rem;
    margin-bottom: 0.9375rem;
    padding: 0 1.5625rem;
    height: 2.5rem;
    font-size: 0.875rem;
  }
}

.test-rezult-block {
  padding: 6.875rem 0;
  display: none;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  background: radial-gradient(43.23rem at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
}

@media (max-width: 767px) {
  .test-rezult-block {
    padding: 2.5rem 0;
  }
}

.test-rezult-block.visible {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transition: all .2s ease;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 80;
  transition: all .3s ease;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -1.25rem;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: top .3s ease, opacity .3s ease;
  overflow: hidden;
  padding: 1.5625rem;
  padding-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .popup {
    padding: 0.625rem;
    padding-bottom: 3.125rem;
  }
}

.popup.top {
  align-items: flex-start;
  position: absolute;
  height: auto;
}

.popup.open {
  opacity: 1;
  top: 0;
  pointer-events: auto;
}

.popup__content {
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.38);
  border-radius: 3.125rem;
  width: 100%;
  max-width: 97.1875rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .popup__content {
    border-radius: 1.5625rem;
  }
}

.popup__content__container {
  position: relative;
}

.btn-close-popup {
  background: #B2D5F0;
  border-radius: 50%;
  overflow: hidden;
  width: 3.625rem;
  height: 3.625rem;
  position: absolute;
  top: 2.5rem;
  right: 5.625rem;
  z-index: 2;
  transition: all .3s ease;
}

.btn-close-popup:hover {
  background: #215AA7;
}

.btn-close-popup:before, .btn-close-popup:after {
  content: "";
  display: block;
  width: 50%;
  height: 0.125rem;
  background-color: #fff;
  position: absolute;
}

.btn-close-popup:before {
  transform: rotate(45deg);
  top: 50%;
  left: 25.5%;
}

.btn-close-popup:after {
  transform: rotate(-45deg);
  top: 50%;
  left: 25.5%;
}

.pharmacies .popup__content {
  max-width: 68.125rem;
}

.pharmacies .popup__container {
  padding: 3.125rem 4.375rem;
}

@media (max-width: 767px) {
  .pharmacies .popup__container {
    padding: 2.5rem 1.25rem;
  }
}

.pharmacies__title {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
  padding-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .pharmacies__title {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.pharmacies__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1875rem solid #D9EEFE;
}

@media (max-width: 767px) {
  .pharmacies__row {
    flex-flow: column;
  }
}

.pharmacies__col {
  min-height: 9.0625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .pharmacies__col {
    min-height: 0;
    width: 100% !important;
    justify-content: center;
  }
}

.pharmacies__col:nth-child(1) {
  width: 15.625rem;
  justify-content: center;
}

@media (max-width: 767px) {
  .pharmacies__col:nth-child(1) {
    min-height: 7.5rem;
  }
}

.pharmacies__col:nth-child(2) {
  width: 33%;
}

.pharmacies__name {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .pharmacies__name {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem;
  }
}

.pharmacies__btn {
  height: 3.625rem;
  padding: 0 1.875rem;
}

@media (max-width: 767px) {
  .pharmacies__btn {
    margin-bottom: 1.875rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    padding: 0 1.25rem;
    height: 2.5rem;
  }
}

@media (max-width: 767px) {
  .pharmacies .btn-close-popup {
    display: none;
  }
}

.medical .popup__content {
  max-width: 51.25rem;
}

.medical__container {
  padding: 0 4.6875rem;
}

@media (max-width: 767px) {
  .medical__container {
    padding: 0 1.25rem;
  }
}

.medical__header {
  padding: 2.8125rem 0;
  border-bottom: 0.1875rem solid #D9EEFE;
}

@media (max-width: 767px) {
  .medical__header {
    padding: 1.25rem 0;
  }
}

.medical__main {
  padding: 1.875rem 0;
}

@media (max-width: 767px) {
  .medical__main {
    padding: 0.9375rem 0;
  }
}

.medical__title {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
}

@media (max-width: 767px) {
  .medical__title {
    font-size: 1.3125rem;
    line-height: 1.6875rem;
  }
}

@media (max-width: 480px) {
  .medical__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.medical__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .medical__desc {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}

@media (max-width: 480px) {
  .medical__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.medical__footer {
  padding: 2.1875rem 0;
  background: #D9EEFE;
}

@media (max-width: 767px) {
  .medical__footer {
    padding: 1.5625rem 0;
  }
}

.medical__footer .medical__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .medical__footer .medical__container {
    flex-flow: column;
  }
}

.medical__footer-desc {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #566EB2;
}

@media (max-width: 767px) {
  .medical__footer-desc {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 480px) {
  .medical__footer-desc {
    font-size: 0.625rem;
    line-height: 0.8125rem;
  }
}

.medical__btn-wrap {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.medical__btn {
  margin-left: 1.5625rem;
  height: 3.625rem;
  display: flex;
  width: 6.4375rem;
  flex-shrink: 0;
}

@media (max-width: 480px) {
  .medical__btn {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin: 0 0.75rem;
  }
}

.medical__btn:last-child {
  background: #B2D5F0;
}

.video-popup .btn-close-popup {
  right: 3.125rem;
}

@media (max-width: 767px) {
  .video-popup .btn-close-popup {
    right: 0;
    top: 0;
  }
}

.video-popup .popup__content {
  padding: 3.125rem;
  width: 70%;
}

@media (max-width: 767px) {
  .video-popup .popup__content {
    width: 95%;
    padding: 3.125rem 1.25rem;
  }
}

.video-popup .video {
  height: 100%;
  width: 100%;
}

.video-popup__video video {
  width: 100%;
  display: block;
}

.success-popup .popup__content {
  padding: 8.75rem 3.125rem 7.5rem;
  width: 100%;
  max-width: 37.5rem;
}

.success-popup .btn-close-popup {
  right: 2.5rem;
}

.success-popup .section-title {
  text-align: center;
}

.faq-select {
  padding: 4.6875rem 0;
  background: #F6FBFF;
}

@media (max-width: 767px) {
  .faq-select {
    padding: 1.875rem 0;
  }
}

.faq-select__row {
  display: flex;
}

@media (max-width: 767px) {
  .faq-select__row {
    flex-flow: column;
  }
}

.faq-select__col {
  width: 50%;
}

@media (max-width: 767px) {
  .faq-select__col {
    width: 100%;
    padding: 0.9375rem 0;
  }
}

.faq-link {
  display: flex;
  align-items: center;
  opacity: 0.65;
}

.faq-link:hover {
  text-decoration: none;
  opacity: 0.85;
}

.faq-link.active {
  opacity: 1;
}

.faq-link__round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.8125rem;
  height: 6.8125rem;
  border-radius: 50%;
  background: #215AA7;
  margin-right: 5rem;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .faq-link__round {
    flex-flow: column;
    width: 3.125rem;
    height: 3.125rem;
    margin-right: 1.25rem;
  }
}

.faq-link__round img {
  max-width: 60%;
}

.faq-link__title {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.875rem;
  text-transform: uppercase;
  color: #215AA7;
}

@media (max-width: 767px) {
  .faq-link__title {
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding-right: 1.25rem;
  }
}

.faq-link__title span {
  display: inline-block;
  border-bottom: 0.1875rem dashed #215AA7;
}

@media (max-width: 767px) {
  .faq-link__title span {
    border-bottom: 0.125rem dashed #215AA7;
  }
}

.faq-section {
  padding-top: 4.5rem;
  background-color: #fff;
  padding-bottom: 1.5625rem;
}

@media (max-width: 767px) {
  .faq-section {
    padding-top: 3.75rem;
    padding-bottom: 0;
  }
}

.faq-item {
  padding: 0 4.6875rem;
  display: flex;
  flex-flow: column;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 3.125rem;
  margin-bottom: 2.8125rem;
  padding-bottom: 2.8125rem;
}

@media (max-width: 767px) {
  .faq-item {
    padding: 0 1.25rem;
    padding-bottom: 1.875rem;
    border-radius: 1.5625rem;
    margin-bottom: 2.1875rem;
  }
}

.faq-item:last-child {
  margin-bottom: 0;
}

.faq-item__head {
  min-height: 6.875rem;
  border-bottom: 0.1875rem solid #D9EEFE;
}

@media (max-width: 767px) {
  .faq-item__head {
    min-height: 3.75rem;
  }
}

.faq-item__title {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
  margin-top: 2.6875rem;
  margin-bottom: 1.4375rem;
}

@media (max-width: 767px) {
  .faq-item__title {
    margin-top: 1.5625rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 480px) {
  .faq-item__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.faq-item__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
  margin-top: 2.1875rem;
}

@media (max-width: 767px) {
  .faq-item__desc {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-top: 1.125rem;
  }
}

.faq-item__list {
  list-style: none;
}

.faq-item__list li {
  font-size: 1.3125rem;
  line-height: 2.125rem;
}

@media (max-width: 767px) {
  .faq-item__list li {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}

.faq-item__link {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #215AA7;
  margin-top: 3.125rem;
  display: inline-block;
}

@media (max-width: 767px) {
  .faq-item__link {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    margin-top: 1.5625rem;
  }
}

.health-formula-img {
  width: 12.6875rem;
}

@media (max-width: 767px) {
  .health-formula-img {
    width: 10.125rem;
  }
}

.pharmacy-number-one {
  width: 11.4375rem;
}

@media (max-width: 767px) {
  .pharmacy-number-one {
    width: 7.3125rem;
  }
}

.pharmacy-ru-img {
  width: 11rem;
}

@media (max-width: 767px) {
  .pharmacy-ru-img {
    width: 7.375rem;
  }
}

.thirty-six-and-six {
  width: 7rem;
}

@media (max-width: 767px) {
  .thirty-six-and-six {
    width: 4.5625rem;
  }
}

.article-head {
  position: relative;
  min-height: 24.4375rem;
  overflow: hidden;
  padding-bottom: 3.125rem;
  padding-top: 0.9375rem;
}

@media (max-width: 767px) {
  .article-head {
    min-height: 9.375rem;
    padding-top: 2.5rem;
  }
}

.article-head .breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
}

.article-head__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  max-width: none;
}

@media (max-width: 767px) {
  .article-head__img {
    height: 100%;
    right: 0;
    left: auto;
    transform: translate(0%, -50%);
  }
}

.article-head__ico {
  margin: 3.75rem auto;
  width: 4.875rem;
  height: 4.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FFFFFF;
}

@media (max-width: 767px) {
  .article-head__ico {
    margin: 0.8125rem auto;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 767px) {
  .article-head__ico img {
    width: 70%;
  }
}

.article-head__pills-img {
  max-width: 2.9375rem;
  width: 100%;
}

.article-head__stomach-img {
  max-width: 2.4375rem;
  width: 100%;
}

.article-head__knife-fork-img {
  width: 3.1875rem;
}

.article-head__medicines-img {
  width: 2.6875rem;
}

@media (max-width: 767px) {
  .article-head__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.article-head__title-wrap {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-top: 1.875rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

@media (max-width: 767px) {
  .article-head__title-wrap {
    min-height: 0rem;
    margin-top: 0rem;
    margin-bottom: 1.25rem;
  }
}

.article-head__for-read {
  font-style: italic;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #0C284E;
}

.article-main-bg {
  background: radial-gradient(54.8125rem at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
}

.article-main .article-head {
  min-height: 26.875rem;
}

@media (max-width: 767px) {
  .article-main .article-head {
    min-height: 13.4375rem;
    margin-bottom: 1.5625rem;
  }
}

.article-main__row {
  display: flex;
}

@media (max-width: 767px) {
  .article-main__row {
    flex-flow: column-reverse;
  }
}

.article-main__col {
  min-height: 31.25rem;
  width: 100%;
  padding-top: 4.375rem;
  padding-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .article-main__col {
    padding: 0;
  }
}

.article-main .aside-col {
  max-width: 24.375rem;
  width: 100%;
  flex-shrink: 0;
  margin-right: 3.4375rem;
}

@media (max-width: 767px) {
  .article-main .aside-col {
    margin-right: 0;
    max-width: 100%;
  }
}

.article-main__head {
  margin-bottom: 4.375rem;
}

@media (max-width: 767px) {
  .article-main__head {
    margin-bottom: 1.875rem;
  }
}

.article-main__title {
  margin-bottom: 0.6875rem;
  text-transform: none;
}

@media (max-width: 767px) {
  .article-main__title {
    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
}

.article-main__info {
  font-style: italic;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #0C284E;
}

.article-main__p {
  margin-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .article-main__p {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

sup {
  position: relative;
  top: -0.3125rem;
  color: inherit;
}

sup a {
  color: inherit;
  font-size: 0.625em;
}

.article-desc.increased-indent {
  padding: 3.125rem 0;
}

@media (max-width: 767px) {
  .article-desc.increased-indent {
    padding: 1.25rem 0;
  }
}

.article-desc__subscription {
  margin: 0 auto;
  padding: 3.75rem 0;
  max-width: 44.0625rem;
  text-align: center;
  font-size: 1.3125rem;
  line-height: 2.125rem;
  text-align: center;
  color: #0C284E;
}

@media (max-width: 767px) {
  .article-desc__subscription {
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 1.875rem 0;
  }
}

.article-card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3.125rem -1.25rem;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .article-card-wrap {
    margin: 0;
    flex-flow: column;
    align-items: center;
  }
}

.article-card {
  max-width: 37.5rem;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 3.125rem;
  position: relative;
  margin: 1.875rem 1.25rem 5.625rem 1.25rem;
}

@media (max-width: 767px) {
  .article-card {
    margin: 0;
    margin-bottom: 1.875rem;
    border-radius: 1.25rem;
    box-shadow: 0rem 0.25rem 1.25rem rgba(188, 213, 247, 0.69);
  }
}

.article-card__content {
  position: relative;
  z-index: 2;
  padding: 3.125rem 3.75rem;
  padding-bottom: 3.125rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 767px) {
  .article-card__content {
    padding: 1.875rem;
  }
}

.article-card__title {
  margin-top: 0.625rem;
  font-weight: 900;
  font-size: 1.625rem;
  line-height: 2.5rem;
  color: #153E75;
  max-width: 19.25rem;
  max-height: 5rem;
  overflow: hidden;
  margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
  .article-card__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    height: auto;
  }
}

.article-card__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
  margin-bottom: 2.1875rem;
  max-width: 23.75rem;
  height: 6.375rem;
  overflow: hidden;
}

@media (max-width: 767px) {
  .article-card__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
    height: auto;
    max-width: 90%;
  }
}

.article-card__img {
  position: absolute;
  right: 2.5rem;
  bottom: 2.8125rem;
}

@media (max-width: 480px) {
  .article-card__img {
    right: 0.625rem;
    bottom: 40%;
  }
}

.article-card__stomach-img {
  width: 9.6875rem;
}

@media (max-width: 767px) {
  .article-card__stomach-img {
    width: 5.875rem;
  }
}

.article-card__woman-img {
  width: 10.5rem;
}

@media (max-width: 767px) {
  .article-card__woman-img {
    width: 5.875rem;
  }
}

.article-card__knife-fork {
  width: 10.5625rem;
  bottom: 1.25rem;
}

@media (max-width: 767px) {
  .article-card__knife-fork {
    width: 5.875rem;
  }
}

.article-card__medicines-img {
  width: 7.875rem;
}

@media (max-width: 767px) {
  .article-card__medicines-img {
    width: 5.625rem;
  }
}

.article-video {
  position: relative;
  margin-bottom: 3.75rem;
  min-height: 1px;
}

@media (max-width: 480px) {
  .article-video {
    margin-bottom: 1.875rem;
  }
}

.article-video .container-2 {
  position: relative;
}

.article-video__poster {
  margin: 0 auto;
}

.article-video__poster img {
  width: 100%;
  max-width: 58.375rem;
  margin: 0 auto;
}

.robot-video {
  position: absolute;
  left: 11.875rem;
  bottom: -3.125rem;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .robot-video {
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: -7.5rem;
  }
}

.robot-video__robot-img-7 {
  max-width: 10.5625rem;
}

@media (max-width: 767px) {
  .robot-video__robot-img-7 {
    max-width: 5.3125rem;
  }
}

.robot-video-say {
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  border-radius: 6.25rem;
  display: flex;
  height: 6.875rem;
  padding-left: 6.5625rem;
  padding-right: 1.25rem;
  position: relative;
  margin-bottom: 6.875rem;
  margin-left: 2.5rem;
}

@media (max-width: 767px) {
  .robot-video-say {
    padding-left: 1.25rem;
    border-radius: 1.875rem;
    align-items: center;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -0.625rem;
    justify-content: space-between;
  }
}

.robot-video-say:before {
  content: "";
  display: block;
  border: 2.125rem solid transparent;
  border-top: 1.9375rem solid #fff;
  position: absolute;
  left: -1.8125rem;
  top: 1.375rem;
  transform: rotate(8deg);
}

@media (max-width: 767px) {
  .robot-video-say:before {
    left: 1.25rem;
    top: -0.625rem;
    transform: rotate(38deg);
  }
}

.robot-video-say__desc {
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #A7BFD2;
  margin-top: 0.9375rem;
  margin-bottom: 0.4375rem;
}

@media (max-width: 767px) {
  .robot-video-say__desc {
    display: none;
  }
}

.robot-video-say__title {
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  color: #0C284E;
  margin-right: 4.375rem;
}

@media (max-width: 767px) {
  .robot-video-say__title {
    margin-right: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}

.robot-video-say .play {
  margin-top: 1.25rem;
}

@media (max-width: 767px) {
  .robot-video-say .play {
    margin-top: 0;
  }
}

.play {
  display: block;
  width: 4.375rem;
  height: 4.375rem;
  background: #215AA7;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .play {
    width: 2.625rem;
    height: 2.625rem;
  }
}

.play:after {
  content: "";
  position: absolute;
  display: block;
  border: 0.625rem solid transparent;
  border-left: 0.9375rem solid #fff;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .play:after {
    border: 0.4375rem solid transparent;
    border-left: 0.625rem solid #fff;
    top: 50%;
    left: 60%;
  }
}

.reasons {
  background: radial-gradient(46.875rem at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
}

.reasons .container-2 {
  background: url("../img/images/man-bg.svg") no-repeat 50% 0/97.625rem;
  padding-top: 6.0625rem;
  padding-bottom: 5.625rem;
  height: 66.25rem;
  position: relative;
}

@media (max-width: 767px) {
  .reasons .container-2 {
    background: url("../img/images/man-bg-mob.svg") no-repeat 50% 0/34.4375rem;
    height: auto;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
}

.reasons__title {
  max-width: 31.25rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.875rem;
}

@media (max-width: 480px) {
  .reasons__title {
    font-size: 1.5625rem;
    line-height: 1.8125rem;
    margin-bottom: 0.625rem;
  }
}

.reasons__desc {
  max-width: 31.25rem;
  font-size: 1.125rem;
  line-height: 2rem;
  color: #0C284E;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .reasons__desc {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}

.stomach-reasons {
  position: absolute;
  bottom: 0;
  left: 51%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .stomach-reasons {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
  }
}

.stomach-reasons__stomach-img-wrap {
  position: relative;
  width: 36.4375rem;
  min-height: 1px;
}

@media (max-width: 767px) {
  .stomach-reasons__stomach-img-wrap {
    max-width: 15.625rem;
    width: 100%;
    margin: 0 auto;
    margin-top: -3.125rem;
  }
}

.stomach-reasons__stomach-img {
  width: 100%;
}

.stomach-reasons .stomach-explanation {
  position: absolute;
}

@media (max-width: 767px) {
  .stomach-reasons .stomach-explanation {
    width: 100% !important;
    position: static !important;
    margin-bottom: 1.25rem;
  }
}

.stomach-explanation {
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  border-radius: 6.25rem;
  padding: 0.625rem 3.75rem;
  padding-right: 1.875rem;
}

@media (max-width: 767px) {
  .stomach-explanation {
    position: static !important;
    margin-bottom: 1.5625rem !important;
    width: 100% !important;
    background: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0 !important;
  }
  .stomach-explanation br {
    display: none;
  }
}

@media (max-width: 767px) {
  .stomach-explanation:after {
    display: none !important;
  }
}

.stomach-explanation:after {
  content: "";
  position: absolute;
  display: block;
}

.stomach-explanation__title {
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #153E75;
  margin: 0.4375rem 0;
  font-weight: 900;
}

@media (max-width: 767px) {
  .stomach-explanation__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.stomach-explanation__desc {
  font-style: italic;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  color: #0C284E;
  margin-bottom: 0.9375rem;
}

@media (max-width: 767px) {
  .stomach-explanation__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.stomach-explanation--1 {
  top: 12rem;
  left: -35%;
  width: 24.375rem;
}

.stomach-explanation--1:after {
  border: 2.0625rem solid transparent;
  border-bottom: 2.0625rem solid #fff;
  top: 2.1875rem;
  right: -0.9375rem;
  transform: rotate(217deg);
}

.stomach-explanation--2 {
  top: 36%;
  right: -19%;
  width: 25rem;
}

.stomach-explanation--2:after {
  border: 2.0625rem solid transparent;
  border-top: 2.0625rem solid #fff;
  top: 3.1875rem;
  left: -0.9375rem;
  transform: rotate(-42deg);
}

.stomach-explanation--3 {
  top: 46%;
  left: -45%;
  width: 18.75rem;
}

.stomach-explanation--3:after {
  border: 2.75rem solid transparent;
  border-bottom: 2.0625rem solid #fff;
  top: 2.125rem;
  right: -2.5625rem;
  transform: rotate(164deg);
}

.stomach-explanation--4 {
  top: 67%;
  left: -15%;
  width: 24.25rem;
}

.stomach-explanation--4:after {
  border: 2.0625rem solid transparent;
  border-bottom: 2.0625rem solid #fff;
  top: 1.875rem;
  right: -1.875rem;
  transform: rotate(175deg);
}

.stomach-explanation--5 {
  bottom: 7%;
  right: -63%;
  width: 27.5rem;
  padding: 1.875rem 3.75rem;
}

.stomach-explanation--5:after {
  border: 2.375rem solid transparent;
  border-top: 2.0625rem solid #fff;
  top: 2.1875rem;
  left: -1.875rem;
  transform: rotate(13deg);
}

.stomach-num {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  color: #215AA7;
  box-shadow: 0rem 0.25rem 1.3125rem rgba(149, 182, 226, 0.57);
  background: #fff;
  position: absolute;
}

.stomach-num--1 {
  top: 29%;
  left: 36%;
}

.stomach-num--2 {
  top: 45%;
  left: 8%;
}

.stomach-num--3 {
  top: 53%;
  left: 80%;
}

.stomach-num--4 {
  top: 79%;
  left: 25%;
}

.stomach-num--5 {
  top: 82%;
  left: 67%;
}

.myths {
  padding-top: 7.1875rem;
  padding-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .myths {
    padding-top: 3.125rem;
  }
}

.myths__row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .myths__row {
    flex-flow: column;
  }
}

.myths__col {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.myths__col:nth-child(1) {
  width: 25rem;
  padding-right: 3.125rem;
  flex-shrink: 0;
  min-height: 0.0625rem;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .myths__col:nth-child(1) {
    padding-right: 0;
    width: auto;
  }
}

.myths__robot-img {
  width: 21.5rem;
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .myths__robot-img {
    display: none;
  }
}

@media (max-width: 767px) {
  .myths__title {
    margin-bottom: 1.875rem;
    text-align: center;
  }
}

.myth {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

@media (max-width: 767px) {
  .myth {
    margin-top: 0;
  }
}

.myth__row {
  display: flex;
}

@media (max-width: 767px) {
  .myth__row {
    flex-flow: column;
  }
}

.myth__ico {
  flex-shrink: 0;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  margin-right: 3.125rem;
  background: #215AA7;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .myth__ico {
    width: 4.6875rem;
    height: 4.6875rem;
    margin-right: 0;
    margin-bottom: 1.875rem;
  }
}

.myth__img-fire {
  width: 4.25rem;
}

.myth__img-drugs {
  width: 3.6875rem;
}

.myth__img-powder {
  width: 4.1875rem;
}

@media (max-width: 767px) {
  .myth__ico-img {
    width: 60%;
  }
}

.myth__title {
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 900;
  color: #153E75;
  margin-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .myth__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin-bottom: 1.25rem;
  }
}

.myth__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
  margin-bottom: 2.1875rem;
}

@media (max-width: 480px) {
  .myth__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem;
  }
}

.after-article {
  position: relative;
  padding-bottom: 6.25rem;
  padding-top: 5rem;
}

@media (max-width: 767px) {
  .after-article {
    padding-bottom: 3.125rem;
    padding-top: 2.5rem;
  }
}

.after-article__cover {
  background: #F6FBFF;
  border-radius: 3.125rem;
  padding: 3.125rem 5.625rem;
  position: relative;
}

@media (max-width: 767px) {
  .after-article__cover {
    padding: 1.875rem;
    border-radius: 1.25rem;
    padding-bottom: 3.125rem;
  }
}

.after-article__info {
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #95B6E2;
  margin-bottom: 1rem;
}

.after-article__title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.875rem;
  text-transform: uppercase;
  color: #215AA7;
  margin-bottom: 2.3125rem;
  max-width: 60%;
}

@media (max-width: 767px) {
  .after-article__title {
    font-size: 1.5rem;
    line-height: 1.875rem;
    max-width: 80%;
  }
  .after-article__title br {
    display: none;
  }
}

@media (max-width: 480px) {
  .after-article__title {
    max-width: 100%;
  }
}

.after-article__img-wrap {
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.after-article__robot-2-img-wrap {
  right: 6.5rem;
  height: 23.125rem;
}

@media (max-width: 767px) {
  .after-article__robot-2-img-wrap {
    right: -1.25rem !important;
    height: 11.25rem !important;
  }
}

.after-article__robot-2-img {
  width: 22.9375rem;
}

@media (max-width: 767px) {
  .after-article__robot-2-img {
    width: 11.4375rem !important;
  }
}

.after-article__robot-3-img-wrap {
  right: 11.4375rem;
  height: 23.75rem;
}

@media (max-width: 767px) {
  .after-article__robot-3-img-wrap {
    right: 1.25rem !important;
    height: 10.8125rem !important;
  }
}

.after-article__robot-3-img {
  width: 14.875rem;
}

@media (max-width: 767px) {
  .after-article__robot-3-img {
    width: 7.75rem !important;
  }
}

.after-article__robot-4-img-wrap {
  right: 11.4375rem;
  height: 21.875rem;
}

@media (max-width: 767px) {
  .after-article__robot-4-img-wrap {
    right: 0.625rem !important;
    height: 11.25rem !important;
  }
}

.after-article__robot-4-img {
  width: 17.8125rem;
}

@media (max-width: 767px) {
  .after-article__robot-4-img {
    width: 9.5rem !important;
  }
}

.after-article__robot-5-img-wrap {
  right: 4.0625rem;
  height: 24.6875rem;
  padding-right: 4.375rem;
}

@media (max-width: 767px) {
  .after-article__robot-5-img-wrap {
    width: 11.25rem;
    height: 8.125rem !important;
    padding-right: 3.125rem;
    right: -2.5rem !important;
  }
}

.after-article__robot-5-img {
  width: 23.625rem;
}

.after-article__heart-img {
  position: absolute;
  right: 0rem;
  top: 1.25rem;
  width: 9.1875rem;
}

@media (max-width: 767px) {
  .after-article__heart-img {
    width: 3.125rem;
  }
}

@media (max-width: 767px) {
  .after-article__link {
    padding: 0 2rem !important;
    font-size: 1rem !important;
  }
}

.btn-read {
  max-width: 9.4375rem;
  padding: 0 3.125rem;
  height: 3.75rem;
}

.signs {
  padding-top: 1.875rem;
  padding-bottom: 3.125rem;
  margin-top: 11.25rem;
}

@media (max-width: 767px) {
  .signs {
    padding-top: 0rem;
    margin-top: 0;
  }
}

.signs__row {
  display: flex;
  justify-content: space-between;
  margin: 0 -1.25rem;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .signs__row {
    flex-flow: column;
    align-items: center;
    margin: 0;
  }
}

.signs .sign {
  margin: 1.25rem;
  width: 100%;
  max-width: 37.5rem;
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.signs .sign::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1.25rem;
  height: 6.25rem;
  background-color: #fff;
  z-index: 3;
}

@media (max-width: 767px) {
  .signs .sign {
    max-width: 100%;
    margin: 1.25rem 0;
    padding-bottom: 4.375rem;
  }
}

@media (max-width: 767px) {
  .signs .sign:first-of-type {
    margin-bottom: 7.5rem;
  }
}

.signs .container-2 {
  position: relative;
}

.signs__robot-img {
  position: absolute;
  right: 33%;
  top: -9.875rem;
  width: 23.625rem;
  z-index: 2;
  pointer-events: none;
  display: none;
}

@media (max-width: 767px) {
  .signs__robot-img {
    display: block;
    left: 1.25rem;
    top: 32%;
    width: 13.625rem;
  }
}

@media (max-width: 480px) {
  .signs__robot-img {
    display: block;
    left: 1.25rem;
    top: 36%;
    width: 12.625rem;
  }
}

.signs__desc {
  max-width: 52.0625rem;
  margin: 2.5rem auto;
  margin-top: 5rem;
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 2.125rem;
  text-align: center;
  color: #215AA7;
}

@media (max-width: 767px) {
  .signs__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 1.25rem auto;
  }
}

.sign {
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 3.125rem;
  padding: 3.75rem 5rem;
}

.sign .signs__robot-img {
  right: 70.3%;
  top: -11.0625rem;
  display: block;
}

@media (max-width: 767px) {
  .sign .signs__robot-img {
    display: none;
  }
}

@media (max-width: 767px) {
  .sign {
    box-shadow: 0rem 0.25rem 1.25rem rgba(188, 213, 247, 0.69);
    border-radius: 1.25rem;
    padding: 1.875rem;
  }
}

.sign:last-child {
  padding-right: 2.5rem;
  z-index: 3;
}

.sign__head {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
  .sign__head {
    flex-flow: column;
    align-items: flex-start;
  }
}

.sign__ico {
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7FBFF;
  margin-right: 2.8125rem;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .sign__ico {
    width: 3.125rem;
    height: 3.125rem;
    background: none;
    margin-right: 0;
    margin-bottom: 0.9375rem;
  }
}

.sign__ico-img-heart {
  width: 4.6875rem;
}

@media (max-width: 767px) {
  .sign__ico-img-heart {
    width: 3.9375rem;
  }
}

.sign__ico-img-stomach {
  width: 3.8125rem;
}

@media (max-width: 767px) {
  .sign__ico-img-stomach {
    width: 3.25rem;
  }
}

.sign__title {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #153E75;
}

@media (max-width: 767px) {
  .sign__title {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.sign__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
  margin-bottom: 2.1875rem;
  max-width: 100%;
}

@media (max-width: 480px) {
  .sign__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem;
  }
}

.sign__list {
  list-style: none;
}

.sign__list li {
  font-size: 1.3125rem;
  line-height: 2.125rem;
}

@media (max-width: 767px) {
  .sign__list li {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

@media (max-width: 767px) {
  .sign__list li b {
    font-weight: normal;
  }
}

.tell-you-wrap-mgt {
  margin-top: 15.625rem;
}

@media (max-width: 767px) {
  .tell-you-wrap-mgt {
    margin-top: 3.125rem;
  }
}

.causes {
  padding-top: 22.5rem;
  padding-bottom: 19.3125rem;
  width: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .causes {
    padding-top: 11.25rem;
    padding-bottom: 2.5rem;
  }
}

.causes__robot {
  width: 19.5rem;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  right: 3.125rem;
}

@media (max-width: 767px) {
  .causes__robot {
    position: absolute;
    width: 12.625rem;
    right: 17%;
    top: -3.125rem;
    z-index: 1;
  }
}

.cause {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0rem 0.25rem 2.6875rem rgba(188, 213, 247, 0.69);
  border-radius: 50%;
  padding: 2.8125rem;
}

@media (max-width: 767px) {
  .cause {
    position: relative !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    border-radius: 1.25rem;
    width: auto !important;
    height: auto !important;
    padding: 1.875rem 1.25rem !important;
    margin-bottom: 1.5625rem;
  }
}

@media (max-width: 767px) {
  .cause {
    z-index: 2;
  }
}

.cause__ico {
  margin: 0 auto;
  margin-bottom: 1.125rem;
  width: 3.4375rem;
  margin-top: 1.5625rem;
}

.cause__title {
  font-weight: 900;
  margin: 0 auto;
  margin-bottom: 1.5625rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #153E75;
  text-align: center;
  max-width: 20rem;
}

@media (max-width: 767px) {
  .cause__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin-bottom: 0.9375rem;
  }
}

.cause__desc {
  margin: 1.25rem auto;
  font-size: 1.3125rem;
  line-height: 2.125rem;
  text-align: center;
  color: #0C284E;
  max-width: 22.5rem;
}

@media (max-width: 767px) {
  .cause__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.cause.cause-1 {
  width: 29.5625rem;
  height: 29.5625rem;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .cause.cause-1 {
    transform: translate(0) !important;
  }
}

.cause.cause-2 {
  width: 25.1875rem;
  height: 25.1875rem;
  top: 13.5625rem;
  left: 0;
}

.cause.cause-3 {
  width: 25.1875rem;
  height: 25.1875rem;
  top: 13.5625rem;
  right: 0;
}

.cause.cause-4 {
  width: 29.5625rem;
  height: 29.5625rem;
  bottom: 7.25rem;
  left: 6.125rem;
  z-index: 2;
  padding: 2.8125rem 3.75rem;
}

.cause.cause-5 {
  width: 29.5625rem;
  height: 29.5625rem;
  bottom: 7.25rem;
  right: 6.125rem;
  z-index: 2;
}

.tip {
  margin-top: 3.125rem;
  margin-bottom: 8.4375rem;
  position: relative;
}

@media (max-width: 767px) {
  .tip {
    margin-bottom: 3.125rem;
    margin-top: 0;
  }
}

.tip:last-child {
  margin-bottom: 0;
}

.tip__row {
  display: flex;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .tip__row {
    flex-flow: column;
  }
}

.tip__col {
  display: flex;
  flex-flow: column;
}

.tip__col:first-child {
  padding-right: 3.125rem;
  width: 28.125rem;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .tip__col:first-child {
    width: 100%;
    padding-right: 0;
    flex-flow: column-reverse;
  }
}

.tip__title {
  margin-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .tip__title {
    font-size: 2.0625rem;
    line-height: 2.625rem;
    text-align: center;
    margin-bottom: 3.125rem;
    margin-top: 1.25rem;
  }
}

.tip__robot-img {
  margin-top: 6.625rem;
}

@media (max-width: 767px) {
  .tip__robot-img {
    position: relative;
    margin: 0 auto;
  }
}

.tip__robot-4-img {
  width: 15.25rem;
}

@media (max-width: 767px) {
  .tip__robot-4-img {
    width: 7.5rem;
  }
}

.tip__robot-9-img {
  width: 12.6875rem;
}

@media (max-width: 767px) {
  .tip__robot-9-img {
    width: 6.25rem;
  }
}

.tip__robot-10-img {
  width: 16.8125rem;
}

@media (max-width: 767px) {
  .tip__robot-10-img {
    width: 7.5rem;
  }
}

.tip__img-bottom {
  width: 16.875rem;
  position: absolute;
  bottom: -1.875rem;
  right: 0;
}

@media (max-width: 767px) {
  .tip__img-bottom {
    width: 8.125rem;
    bottom: 1.875rem;
  }
}

.tip-list {
  list-style: none;
}

.tip-list li {
  background: url("../img/icons/check-ico.svg") no-repeat 0 0.3125rem/1.8125rem;
  margin-bottom: 2.5rem;
  padding-left: 3.75rem;
  max-width: 46.25rem;
}

@media (max-width: 767px) {
  .tip-list li {
    margin-bottom: 1.5625rem;
    padding-left: 3.125rem;
    background-size: 1.125rem;
    background-position: 0 0.375rem;
  }
}

.tip-list__title {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #153E75;
  margin-bottom: 0.3125rem;
}

@media (max-width: 767px) {
  .tip-list__title {
    font-size: 1.125rem;
    line-height: 1.4375rem;
  }
}

.tip-list__desc {
  font-size: 1.3125rem;
  line-height: 2.125rem;
  color: #0C284E;
}

@media (max-width: 767px) {
  .tip-list__desc {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

.widget {
  margin-top: 1.25rem;
  margin-bottom: 4.375rem;
  padding: 2.1875rem;
  background: #F6FBFF;
  display: flex;
  flex-flow: column;
  max-width: 24.375rem;
}

@media (max-width: 767px) {
  .widget {
    padding: 1.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.widget__img {
  width: 100%;
}

.widget__title {
  font-size: 1.5rem;
  line-height: 1.9375rem;
  text-align: center;
  color: #215AA7;
  margin: 2.5rem 0;
}

@media (max-width: 767px) {
  .widget__title {
    font-size: 1.125rem;
    line-height: 1.3125rem;
  }
}

.widget .btn {
  align-self: center;
  margin-bottom: 1.25rem;
}

.widget__robot-img {
  position: relative;
  margin: 0 auto;
  width: 10rem;
  right: 1.6875rem;
  position: relative;
  margin-top: -4.375rem;
}

@media (max-width: 767px) {
  .widget__robot-img {
    width: 7.5rem;
    right: 0.9375rem;
  }
}

.breadcrumbs {
  padding: 2.75rem 4.0625rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding: 1.625rem;
  }
}

.breadcrumbs__link {
  display: block;
  line-height: 2.25rem;
  margin-right: 1.25rem;
  background-color: #FFFFFF;
  padding: 0 1rem;
  border-radius: 1.25rem;
  color: #215AA7;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all .2s;
  max-width: 15.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 21.875rem;
}

@media (max-width: 767px) {
  .breadcrumbs__link {
    line-height: 1.25rem;
    font-size: 0.625rem;
    padding: 0 0.8125rem;
    margin-right: 1.125rem;
    margin-bottom: 0.625rem;
  }
}

@media (max-width: 480px) {
  .breadcrumbs__link {
    display: none;
  }
}

.breadcrumbs__link:nth-child(1), .breadcrumbs__link:nth-child(2) {
  display: inline-flex;
}

.breadcrumbs__link svg path, .breadcrumbs__link svg rect {
  transition: all .3s;
}

.breadcrumbs__link:hover {
  text-decoration: none;
  background: #215AA7;
  color: #fff;
}

.breadcrumbs__link:hover svg path, .breadcrumbs__link:hover svg rect {
  fill: #fff;
}

.breadcrumbs__home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  border-radius: 50%;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .breadcrumbs__home {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.breadcrumbs__home svg {
  width: 1rem;
  margin-bottom: 0.1875rem;
}

@media (max-width: 767px) {
  .breadcrumbs__home svg {
    width: 0.5rem;
    margin-bottom: 0;
  }
}
