.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	z-index: 80;
	@include transition;
}

.popup {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: rem(-20);
	width: 100%;
	height: 100vh;
	left: 0;
	z-index: 100;
	pointer-events: none;
	opacity: 0;
	transition: top .3s ease, opacity .3s ease;
	overflow: hidden;
	padding: rem(25);
	padding-bottom: rem(50);


	@media (max-width: 767px) {
		padding: rem(10);
		padding-bottom: rem(50);
	}
	

	&.top {
		align-items: flex-start;
		position: absolute;
		height: auto;
	}

	&.open {
		opacity: 1;
		top: 0;
		pointer-events: auto;
	}

	&__content {
		background: #FFFFFF;
		box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.38);
		border-radius: rem(50);
		width: 100%;
		max-width: rem(1555);
		position: relative;
		overflow: hidden;

		@media (max-width: 767px) {
			border-radius: rem(25);
		}

		&__container {
			position: relative;
		}
	}
}


.btn-close-popup {
	background: #B2D5F0;
	border-radius: 50%;
	overflow: hidden;
	width: rem(58);
	height: rem(58);
	position: absolute;
	top: rem(40);
	right: rem(90);
	z-index: 2;
	@include transition;

	&:hover {
		background: #215AA7;
	}

	&:before,
	&:after {
		content: "";
		display: block;
		width: 50%;
		height: rem(2);
		background-color: #fff;
		position: absolute;
	}

	&:before {
		transform: rotate(45deg);
		top: 50%;
		left: 25.5%;
	}

	&:after {
		transform: rotate(-45deg);
		top: 50%;
		left: 25.5%;
	}
}

.pharmacies {

	
	.popup__content {
		max-width: rem(1090);
	}

	.popup__container {
		padding: rem(50) rem(70);

		@media (max-width: 767px) {
			padding: rem(40) rem(20);
		}
	}

	&__title {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		color: #153E75;
		padding-bottom: rem(40);

		@media (max-width: 767px) {
			text-align: center;
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: rem(3) solid #D9EEFE;

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__col {
		min-height: rem(145);
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			min-height: 0;
			width: 100% !important;
			justify-content: center;
		}

		&:nth-child(1) {
			width: rem(250);
			justify-content: center;

			@media (max-width: 767px) {
				min-height: rem(120);
			}
		}

		&:nth-child(2) {
			width: 33%;
		}

		&:nth-child(3) {
		}
	}

	&__name {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			margin-bottom: rem(20);
		}
	}

	&__btn {
		height: rem(58);
		padding: 0 rem(30);

		@media (max-width: 767px) {
			margin-bottom: rem(30);
			font-size: rem(14);
			line-height: rem(17);
			padding: 0 rem(20);
			height: rem(40);
		}

		
	}

	.btn-close-popup {
		@media (max-width: 767px) {
			display: none;
		}
	}
}

.medical {

	.popup__content {
		max-width: rem(820);
	}

	&__container {
		padding: 0 rem(75);

		@media (max-width: 767px) {
			padding: 0 rem(20);
		}
	}
	
	&__header {
		padding: rem(45) 0;
		border-bottom: rem(3) solid #D9EEFE;

		@media (max-width: 767px) {
			padding: rem(20) 0;
		}
	}

	&__main {
		padding: rem(30) 0;

		@media (max-width: 767px) {
			padding: rem(15) 0;
		}
	}

	&__title {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		color: #153E75;

		@media (max-width: 767px) {
			font-size: rem(21);
			line-height: rem(27);
		}

		@media (max-width: 480px) {
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(25);
		}

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&__footer {
		padding: rem(35) 0;
		background: #D9EEFE;

		@media (max-width: 767px) {
			padding: rem(25) 0;
		}

		.medical__container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width: 767px) {
				flex-flow: column;
			}
		}
	}

	&__footer-desc {
		font-weight: 600;
		font-size: rem(14);
		line-height: rem(21);
		color: #566EB2;

		@media (max-width: 767px) {
			margin-bottom: rem(40);
		}


		@media (max-width: 480px) {
			font-size: rem(10);
			line-height: rem(13);
		}
	}

	&__btn-wrap {
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	&__btn {
		margin-left: rem(25);
		height: rem(58);
		display: flex;
		width: rem(103);
		flex-shrink: 0;

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(17);
			margin: 0 rem(12);
		}

		&:last-child {
			background: #B2D5F0;
		}
	}
}

.video-popup {
	.btn-close-popup {
		right: rem(50);

		@media (max-width: 767px) {
			right: 0;
			top: 0;
		}
	}

	.popup__content {
		padding: rem(50);
		// height: 90vh;
		width: 70%;

		@media (max-width: 767px) {
			width: 95%;
			// height: 80vh;
			padding: rem(50) rem(20);
		}
	}

	.video {
		height: 100%;
		width: 100%;
	}

	&__video {

		video {
			width: 100%;
			display: block;
		}
	}
}

.success-popup {
	.popup__content {
		padding: rem(140) rem(50) rem(120);
		width: 100%;
		max-width: rem(600);
	}

	.btn-close-popup {
		right: rem(40);
	}

	.section-title {
		text-align: center;
	}
}
