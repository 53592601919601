$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function lh($lh, $fs) {
  @return #{$lh/$fs};
}


@mixin transition {
	transition: all .3s ease;
}

@import "_fonts";
@import "_reset";

@import "_common.scss";
@import "_header.scss";
@import "_footer.scss";
@import "_main.scss";
@import "_test.scss";
@import "_popup.scss";
@import "_faq.scss";
@import "_images.scss";
@import "_articles.scss";