.health-formula-img {
	width: rem(203);

	@media (max-width: 767px) {
		width: rem(162);
	}
}

.pharmacy-number-one {
	width: rem(183);

	@media (max-width: 767px) {
		width: rem(117);
	}
}

.pharmacy-ru-img {
	width: rem(176);

	@media (max-width: 767px) {
		width: rem(118);
	}
}

.thirty-six-and-six {
	width: rem(112);

	@media (max-width: 767px) {
		width: rem(73);
	}
}