.header {
	.container {
		display: flex;
		height: rem(180);
		background: #ffffff;
		align-items: center;
		justify-content: space-between;
	}
}

.logo {
	display: block;
	width: 100%;
	max-width: rem(275);

	img {
		width: 100%;
	}
}

.nav {
	display: flex;
	width: 100%;
	margin: 0 rem(100);
	list-style: none;
	align-items: center;
	justify-content: space-between;

	a {
		font-family: "Helvetica Neue";
		font-size: rem(16);
		font-weight: bold;
		font-style: normal;
		line-height: rem(19);
		text-transform: uppercase;
		color: #0c284e;

		&:hover {
			text-decoration: none;
			color: #215aa7;
		}

		&.active {
			color: #215aa7;
		}
	}
}

.dropdown-link {

	@media (max-width: 767px) {
		width: 100%;
	}

	& > a {
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			right: rem(-20);
			display: block;
			width: rem(8);
			height: rem(7);
			content: "";
			transform: translate(0, -50%);
			background: url("../img/icons/menu-item-arrow.png") no-repeat 50% / rem(8);
		}

		&:before {
			position: absolute;
			top: rem(75);
			left: 50%;
			display: block;
			display: none;
			content: "";
			transform: translate(-50%, 0);
			border: rem(10) solid transparent;
			border-bottom: rem(13) solid #215aa7;
		}
	}

	&.open {

		.sub-nav {
			pointer-events: auto;
			opacity: 1;
		}

		& > a {

			&:before {
				display: block;
			}

			&:after {
				top: 37%;
				transform: rotate(-180deg);
			}
		}
	}
}

.header-btn {
	height: rem(60);
	padding: 0 rem(47);
	white-space: nowrap;
	flex-shrink: 0;
}

.mob-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 50;

	.container {
		height: rem(65);
	}

	.logo {
		max-width: rem(121);
	}
}

.menu-btn {
	display: block;
	width: rem(30);
	height: rem(17);
	transition: all .3s ease;

	&.close-menu {
		.line {
			position: relative;

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(1) {
				top: rem(7);
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				bottom: rem(7);
				transform: rotate(-45deg);
			}
		}
	}

	&:hover {
		height: rem(17);
	}

	.lines {
		display: block;
		display: flex;
		width: 100%;
		height: 100%;
		flex-flow: column;
		justify-content: space-between;
	}

	.line {
		display: block;
		width: 100%;
		height: rem(3);
		transition: all .2s ease;
		border-radius: rem(10);
		background: #0c284e;
	}
}

.mob-menu {
	position: fixed;
	z-index: 40;
	top: 0%;
	width: 100%;
	padding: rem(65) rem(20);
	transition: all .5s ease;
	transform: translate(0, -100%);
	background-color: #ffffff;
	max-height: 100vh;
	overflow: auto;

	@media (min-width: 768px) {
		display: none !important;
	}

	&.open {
		transform: translate(0, 0%);
	}

	.nav {
		margin: 0;
		padding: rem(20);
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;

		@media (max-width: 767px) {
			padding: rem(20) 0;
		}

		li {
			margin: rem(10) 0;
		}

		a {
			font-size: rem(18);
			line-height: rem(21);
		}
	}
}

.sub-nav {
	position: absolute;
	z-index: 20;
	top: 100%;
	right: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: rem(155);
	list-style: none;
	pointer-events: none;
	opacity: 0;
	background: #215aa7;
	align-items: center;
	justify-content: center;
	transition: all .2s;

	@media (max-width: 767px) {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
		width: 100%;
		height: auto;
		padding: 0;
		pointer-events: auto;
		opacity: 1;
		flex-flow: column;
		padding: rem(20) 0;
		background: #F2F7FB;
	}


	&__li {
		position: relative;
		padding: 0 rem(80);

		@media (max-width: 767px) {
			padding: 0 rem(20);
			width: 100%;
			text-align: left;

			br {
				display: none;
			}
		}

		&:after {
			position: absolute;
			top: rem(13);
			right: 0;
			display: block;
			width: rem(2);
			height: rem(40);
			content: "";
			background-color: #346ab3;

			@media (max-width: 767px) {
				display: none;
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		a {
			font-size: rem(16);
			font-weight: bold;
			line-height: rem(24);
			display: flex;
			text-transform: uppercase;
			color: #ffffff;
			align-items: center;

			@media (max-width: 767px) {
				align-items: flex-start;
				text-align: left;
				color: #0C284E;
			}

			&:hover {
				text-decoration: underline;
				color: #ffffff;

				@media (max-width: 767px) {
					color: #215AA7;
					text-decoration: none;
				}
			}
		}
	}

	&__ico {
		margin-right: rem(40);

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__ico-1 {
		width: rem(59);
	}

	&__ico-2 {
		width: rem(83);
	}

	&__ico-3 {
		width: rem(81);
	}

	&__ico-4 {
		width: rem(60);
	}

	&__ico-5 {
		width: rem(85);
	}
}
