.test {
	padding-bottom: rem(120);
	background: radial-gradient(rem(691.68) at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
	padding-top: rem(20);
	position: relative;
	display: none;

	@media (max-width: 767px) {
		padding-bottom: 0;
		padding-top: 0;
	}

	&.visible {
		display: block;
		opacity: 1;
		pointer-events: auto;
		transition: all .2s ease;
	}


	&__wrap {
		display: flex;
		justify-content: space-between;
		padding-left: rem(128);

		@media (max-width: 767px) {
			flex-flow: column;
			padding-left: 0;
		}
	}

	&__wrap-rezult {
		justify-content: center;
		padding-left: 0;
		align-items: center;

		.test__left {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: rem(128);
		}

		.test__right {
			width: auto;

			&:after {
				display: none;
			}
		}
	}

	&__title {
		color: #215AA7;
		margin-top: rem(95);
		margin-bottom: rem(20);

		@media (max-width: 767px) {
			background: #215AA7;
			padding: rem(22) rem(10);
			padding-bottom: rem(20);
			color: #FFFFFF;
			margin: 0;
			text-align: center;
		}

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&__title-small {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(35);
		color: #153E75;
		text-transform: none;

		@media (max-width: 767px) {
			padding: rem(4) rem(10);
			padding-bottom: rem(8);
			text-align: center;
			background: #0C284E;
			color: #FFFFFF;

			br {
				display: none;
			}
		}

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&__desc {
		font-weight: 450;
		font-size: rem(14);
		line-height: rem(18);
		color: #95B6E2;
		margin-top: rem(143);
		max-width: rem(360);

		@media (max-width: 767px) {
			margin: 0;
			display: none;
		}
	}

	&__right {
		position: relative;
		width: 70%;
		margin-top: rem(80);

		@media (max-width: 767px) {
			width: 100%;
			margin-top: 0;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			height: rem(137);
			width: 91%;
			max-width: rem(950);
			top: 0;
			background: linear-gradient(180deg, #F8FCFF 3.12%, #F6FBFF 32.81%, rgba(255, 255, 255, 0) 100%);
			z-index: 5;

			@media (max-width: 767px) {
				display: none;
			}
		}
	}

	&__scroll {
		overflow: auto;
		max-height: rem(520);
		overflow-x: hidden;
	}

	&__cover {
		padding-top: rem(100);
		padding-right: rem(75);
		width: 100%;
		padding-bottom: rem(40);

		@media (max-width: 767px) {
			padding: 0;
			padding-right: rem(20);
			padding-top: rem(30);
			
		}
	}

	&__robot-img {
		width: rem(361);

		@media (max-width: 767px) {
			display: none;
		}
	}
}

.test-answer {
	display: flex;
	margin-bottom: rem(45);
	max-width: rem(890);

	&.answered {
		pointer-events: none;
		
		.test-answer__btn {
			background: #F3FAFF;
			color: #95B6E2;
		}
	}

	@media (max-width: 767px) {
		flex-flow: column;
		padding: 0 rem(20);
		margin-bottom: rem(33);
	}

	&__content {
		box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
		border-radius: rem(100);
		position: relative;
		display: flex;
		padding: rem(15) 0;

		@media (max-width: 767px) {
			background: #FFFFFF;
			box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
			border-radius: rem(12);
		}

		@media (max-width: 480px) {
			width: 100%;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
		}
	}

	&__col {
		position: relative;
		z-index: 5;
	}

	&__participant-img {
		margin-top: rem(20);
		width: rem(85);
		height: rem(85);
		flex-shrink: 0;

		@media (max-width: 767px) {
			width: rem(40);
			height: rem(40);
			margin: 0;
			margin-bottom: rem(20);
		}

		img {
			width: 100%;
		}
	}

	&__info {
		font-weight: 450;
		font-size: rem(14);
		line-height: rem(18);
		display: block;
		margin-bottom: rem(7);

		@media (max-width: 480px) {
			font-size: rem(10);
			line-height: rem(13);
		}
	}

	&__text {
		font-family: 'Futura PT';
		font-style: italic;
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(23);
		margin-bottom: rem(12);
		min-height: rem(50);
		max-width: rem(360);

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		margin-left: rem(20);

		@media (max-width: 767px) {
			margin-left: 0;
		}

		@media (max-width: 480px) {
			justify-content: space-between;
			margin: 0;
		}
	}

	&__btn {
		width: rem(103);
		height: rem(58);
		background: #215AA7;
		border-radius: rem(100);
		margin-left: rem(25);

		&.selected {
			color: #fff !important;
			background: #215AA7 !important;
		}

		@media (max-width: 767px) {
			margin-left: 0;
			margin-right: rem(25);
			height: rem(50);
		}

		@media (max-width: 480px) {
			margin: 0;
			height: rem(38);
			font-size: rem(14);
			line-height: rem(17);
			font-weight: bold;
		}
	}

	&__btn-result {
		width: rem(170);
		height: rem(58);
		margin-left: rem(30);
		
		@media (max-width: 767px) {
			margin-left: 0;
		}
	}

	&__prints {
		width: rem(27);
		height: rem(27);
		border-radius: rem(50);
		background: #FFFFFF;
		box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
		color: #95B6E2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: rem(50);

		@media (max-width: 767px) {
			margin-top: rem(12);
		}

		i {
			width: rem(3);
			height: rem(3);
			background: #95B6E2;
			border-radius: rem(3);
			margin: 0 rem(1);
			animation: test-answer-ellipsis 1s ease infinite;
			animation-fill-mode: forwards;

			&:nth-child(1) {
				animation-delay: 0s;
			}

			&:nth-child(2) {
				animation-delay: .3s;
			}

			&:nth-child(3) {
				animation-delay: .7s;
			}
		}
	}

	&__col-img {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin-left: rem(12);

		@media (max-width: 767px) {
			margin-left: 0;
			margin-right: rem(12);
		}
	}

	&__img-zzz {
		width: rem(54);

		@media (max-width: 480px) {
			width: rem(32);
		}
	}

	&.robot-answer {
		justify-content: flex-start;

		.test-answer {

			&__participant-img {
				margin-right: rem(65);
				background: url("../img/icons/test-robot.png") no-repeat 50% / contain;
				
				@media (max-width: 767px) {
					margin-left: rem(20);
					margin-bottom: rem(10);
					margin-top: 0;
				}
			}

			&__content {
				padding-left: rem(65);
				padding-right: rem(30);
				background: #FFFFFF;

				@media (max-width: 767px) {
					padding: rem(18);
					flex-flow: column;
				}

				&:before {
					border: rem(39) solid transparent;
					border-top: rem(34) solid #fff;
					left: rem(-22);
					top: rem(22);
					transform: rotate(-24deg);

					@media (max-width: 767px) {
						left: 27%;
						top: rem(-13);
						transform: rotate(29deg);
					}
				}
			}

			&__info {
				color: #A7BFD2;
			}
		}

		&.test-print {

			@media (max-width: 767px) {
				flex-flow: row;
			}

			.test-answer {
				
				&__participant-img {
					margin-right: rem(60);

					@media (max-width: 767px) {
						margin-right: rem(20);
					}
				}
			}
		}
	}

	&.user-answer {
		justify-content: flex-end;

		@media (max-width: 767px) {
			flex-flow: column-reverse;
			align-items: flex-end;
		}

		.test-answer {

			&__participant-img {
				margin-left: rem(65);
				margin-right: rem(30);
				background: url("../img/icons/test-user.png") no-repeat 50% / contain;

				@media (max-width: 767px) {
					margin-right: rem(20);
					margin-bottom: rem(10);
					margin-top: 0;
				}
			}

			&__content {
				padding: rem(15) 0;
				background: #215AA7;
				padding-left: rem(70);
				padding-right: rem(50);

				@media (max-width: 767px) {
					padding: rem(20);
					flex-flow: row-reverse;
				}

				&:before {
					border: rem(60) solid transparent;
					border-bottom: rem(34) solid #215AA7;
					right: rem(-30);
					top: rem(-43);
					transform: rotate(-4deg);

					@media (max-width: 767px) {
						right: 42%;
						top: rem(-43);
						transform: rotate(-58deg);
					}
				}
			}

			&__info {
				color: #3870BB;
			}

			&__text {
				color: #FFFFFF;
				max-width: rem(360);
			}
		}

		&.test-print {
			flex-flow: row-reverse;
			justify-content: flex-start;

			@media (max-width: 767px) {
				flex-flow: row;
				align-items: flex-start;
				justify-content: flex-end;
			}

			.test-answer {

				&__participant-img {
					margin-left: rem(37);

					@media (max-width: 767px) {
						margin-left: rem(20);
					}
				}
			}
		}
	}
}

@keyframes test-answer-ellipsis {
	0% {background: #215AA7;}
	100% {background: #95B6E2;}
}

.test-end-btn {
	display: block;
	width: 100%;
	padding: rem(16);
	font-weight: bold;
	text-align: center;
	font-size: rem(18);
	line-height: rem(21);
	text-transform: uppercase;
	color: #EFF8FF;
	background: #C1DDF1;
	border-radius: 0;

	@media (max-width: 480px) {
		font-size: rem(14);
		line-height: rem(17);
	}
}

.test-result {
	padding: rem(45) rem(73);
	font-size: rem(24);
	line-height: rem(40);
	color: #153E75;
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
	border-radius: rem(50);
	max-width: rem(817);
	margin: rem(20);

	@media (max-width: 767px) {
		padding: rem(20);
		border-radius: rem(20);
	}

	&__title {
		font-weight: 900;
		padding-bottom: rem(23);
		border-bottom: rem(3) solid #D9EEFE;
		margin-bottom: rem(35);

		@media (max-width: 767px) {
			margin: rem(18);
			font-size: rem(21);
			line-height: rem(24);
			padding-bottom: rem(15);
		}
	}

	&__desc {
		margin: rem(38) 0;
		font-size: rem(21);
		line-height: rem(34);

		@media (max-width: 767px) {
			font-size: rem(15);
			line-height: rem(21);
			margin: rem(15);
		}
	}

	&__btn {
		padding: 0 rem(36);
		height: rem(58);
		margin-top: rem(58);
		margin-bottom: rem(30);

		@media (max-width: 767px) {
			margin-top: rem(20);
			margin-bottom: rem(15);
			padding: 0 rem(25);
			height: rem(40);
			font-size: rem(14);
		}
	}
}

.test-rezult-block {
	padding: rem(110) 0;
	display: none;
	z-index: 5;
	opacity: 0;
	pointer-events: none;
	background: radial-gradient(rem(691.68) at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
	
	@media (max-width: 767px) {
		padding: rem(40) 0;
	}

	&.visible {
		display: block;
		opacity: 1;
		pointer-events: auto;
		transition: all .2s ease;
	}
}