.contraindications {
	background: #D9EEFE;

	h3 {
		font-family: 'Futura PT Cond Book';
		font-size: rem(41);
		line-height: rem(53);
		padding: rem(3) 0;
		letter-spacing: -0.01em;
		color: #95B6E2;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		text-transform: uppercase;

		@media (max-width: 767px) {
			font-size: rem(25);
			line-height: rem(30);
			text-align: left;
			padding: rem(10) 0;
		}

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}
}

.footer {
	background: #F2F7FB;

	.logo {
		max-width: rem(178);
		margin-bottom: rem(56);

		@media (max-width: 767px) {
			max-width: rem(171);
		}
	}

	&__row {
		display: flex;
		padding-bottom: rem(36);
		padding-top: rem(38);

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__col {

		&:nth-child(1) {
			width: 18%;
		}

		&:nth-child(2) {
			width: 24%;
		}

		&:nth-child(3) {
			width: 24%;
		}

		&:nth-child(4) {
			width: 20%;
		}

		&:nth-child(5) {
			
		}

		@media (max-width: 767px) {
			width: 100% !important;
		}
	}

	&__cover {
		padding-top: rem(30);

		@media (max-width: 767px) {
			padding-top: 0;
		}
	}

	.bordbtm {
		border-bottom: rem(3) solid #DFEAF8;

		@media (max-width: 767px) {
			padding-bottom: 0;
		}
	}

	&__link {
		font-family: "Helvetica Neue";
		font-style: normal;
		font-weight: bold;
		font-size: rem(12);
		line-height: rem(15);
		text-transform: uppercase;
		color: #215AA7;
		display: block;

		@media (max-width: 767px) {
			margin-bottom: rem(15);
		}
	}


	.btn2 {
		&__ico {

			@media (max-width: 767px) {
				margin-right: 0;
			}

			&:before,
			&:after {
				display: none;
			}
		}

		@media (max-width: 767px) {
			padding: 0;
			height: auto;
			background: none;
			align-self: flex-start;
			width: auto;
		}


		&__desc {

			@media (max-width: 767px) {
				display: none;
				background-color: #fff;
			}
		}


		&:hover {
			@media (max-width: 767px) {
				background-color: #153E75;
			}

			.btn2__ico {
	
				@media (max-width: 767px) {
					background-color: #153E75;
				}
				
				svg {
					line {
						@media (max-width: 767px) {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
}

.logo-col {
	@media (max-width: 767px) {
		display: flex;
		justify-content: space-between;
	}
}

.footer-nav {
	margin-bottom: rem(25);

	@media (max-width: 767px) {
		margin-bottom: rem(40);
	}

	&--last {
		@media (max-width: 767px) {
			margin-bottom: rem(25);
		}
	}

	&__title {
		font-family: "Helvetica Neue";
		font-weight: bold;
		font-size: rem(16);
		line-height: rem(19);
		text-transform: uppercase;
		color: #215AA7;
		padding-bottom: rem(25);
		margin-bottom: rem(25);
		border-bottom: rem(3) solid #DFEAF8;
		min-width: rem(201);
		display: inline-block;

		@media (max-width: 767px) {
			width: 100%;
			padding-bottom: rem(10);
			margin-bottom: rem(10);
			font-size: rem(14);
			line-height: rem(17);
		}
	}

	&__list {
		list-style: none;
		padding: 0;

		li {
			display: block;
			line-height: 1;
		}

		a {
			font-weight: 450;
			font-size: rem(16);
			line-height: rem(24);
			color: #0C284E;

			@media (max-width: 767px) {
				font-size: rem(14);
				line-height: rem(14);
			}
		}
	}
}

.scroll-up {
	padding: rem(13) rem(17);
	background-color: #fff;
	padding-right: rem(20);
	flex-shrink: 0;


	&:hover {
		background-color: #fff;
		text-decoration: none;
		box-shadow: rem(0) rem(0) rem(25) rem(-3) rgba(0, 0, 0, 0.25);
	}

	@media (max-width: 767px) {
		padding: 0;
		height: auto;
		background: none;
		align-self: flex-start;
	}

	&__round {
		background-color: #215AA7;
		border-radius: 50%;
		width: rem(52);
		height: rem(52);
		margin-right: rem(19);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		@media (max-width: 767px) {
			margin-right: 0;
		}
	}

	&__ico {
		width: rem(16);

		&:before,
		&:after {
			display: none;
		}
	}

	&__desc {
		font-weight: bold;
		font-size: rem(16);
		line-height: rem(19);

		color: #215AA7;
		text-align: left;

		@media (max-width: 767px) {
			display: none;
		}
	}
}

.copyright {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: bold;
	font-size: rem(12);
	line-height: rem(15);
	text-transform: uppercase;
	color: #0C284E;

	@media (max-width: 767px) {
		margin-bottom: rem(15);
	}
}