.faq-select {
	padding: rem(75) 0;
	background: #F6FBFF;

	@media (max-width: 767px) {
		padding: rem(30) 0;
	}

	&__row {
		display: flex;

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__col {
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
			padding: rem(15) 0;
		}
	}
}

.faq-link {
	display: flex;
	align-items: center;
	opacity: 0.65;

	&:hover {
		text-decoration: none;
		opacity: 0.85;
	}

	&.active {
		opacity: 1;
	}

	&__round {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(109);
		height: rem(109);
		border-radius: 50%;
		background: #215AA7;
		margin-right: rem(80);
		flex-shrink: 0;

		@media (max-width: 767px) {
			flex-flow: column;
			width: rem(50);
			height: rem(50);
			margin-right: rem(20);
		}

		img {
			max-width: 60%;
		}
	}

	&__title {
		display: inline-block;
		font-weight: bold;
		font-size: rem(48);
		line-height: rem(62);
		text-transform: uppercase;
		color: #215AA7;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(22);
			padding-right: rem(20);
		}

		span {
			display: inline-block;
			border-bottom: rem(3) dashed #215AA7;

			@media (max-width: 767px) {
				border-bottom: rem(2) dashed #215AA7;
			}
		}
	}
}

.faq-section {
	padding-top: rem(72);
	background-color: #fff;
	padding-bottom: rem(25);

	@media (max-width: 767px) {
		padding-top: rem(60);
		padding-bottom: 0;
	}
}

.faq-item {
	padding: 0 rem(75);
	display: flex;
	flex-flow: column;
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
	border-radius: rem(50);
	margin-bottom: rem(45);
	padding-bottom: rem(45);

	@media (max-width: 767px) {
		padding: 0 rem(20);
		padding-bottom: rem(30);
		border-radius: rem(25);
		margin-bottom: rem(35);
	}

	&:last-child {
		margin-bottom: 0;
	}
	
	&__head {
		min-height: rem(110);
		border-bottom: rem(3) solid #D9EEFE;

		@media (max-width: 767px) {
			min-height: rem(60);
		}
	}

	&__title {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		color: #153E75;
		margin-top: rem(43);
		margin-bottom: rem(23);

		@media (max-width: 767px) {
			margin-top: rem(25);
			margin-bottom: rem(20);
		}

		@media (max-width: 480px) {
			font-size: rem(18);
			line-height: rem(23);
		}

	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;
		margin-top: rem(35);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(21);
			margin-top: rem(18);
		}
	}

	&__list {
		list-style: none;

		li {
			font-size: rem(21);
			line-height: rem(34);

			@media (max-width: 767px) {
				font-size: rem(14);
				line-height: rem(21);
			}
		}
	}

	&__link {
		font-weight: bold;
		font-size: rem(16);
		line-height: rem(19);
		text-transform: uppercase;
		color: #215AA7;
		margin-top: rem(50);
		display: inline-block;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(19);
			margin-top: rem(25);
		}

	}
}