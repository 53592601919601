.article-head {
	position: relative;
	min-height: rem(391);
	overflow: hidden;
	padding-bottom: rem(50);
	padding-top: rem(15);

	@media (max-width: 767px) {
		min-height: rem(150);
		padding-top: rem(40);
	}

	.breadcrumbs {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		max-width: none;

		@media (max-width: 767px) {
			height: 100%;
			right: 0;
			left: auto;
			transform: translate(0%, -50%);
		}
	}

	&__ico {
		margin: rem(60) auto;
		width: rem(78);
		height: rem(78);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #FFFFFF;

		@media (max-width: 767px) {
			margin: rem(13) auto;
			width: rem(40);
			height: rem(40);
		}

		img {
			@media (max-width: 767px) {
				width: 70%;
			}
		}
	}

	&__pills-img {
		max-width: rem(47);
		width: 100%;
	}

	&__stomach-img {
		max-width: rem(39);
		width: 100%;
	}

	&__knife-fork-img {
		width: rem(51);
	}

	&__medicines-img {
		width: rem(43);
	}

	&__title {
		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	&__title-wrap {
		// min-height: rem(124);
		display: flex;
		flex-flow: column;
		justify-content: center;
		margin-top: rem(30);
		margin-bottom: rem(40);
		text-align: center;

		@media (max-width: 767px) {
			min-height: rem(0);
			margin-top: rem(0);
			margin-bottom: rem(20);
		}
	}

	&__for-read {
		font-style: italic;
		font-weight: 600;
		font-size: rem(14);
		line-height: rem(18);
		text-align: center;

		color: #0C284E;
	}
}

.article-main-bg {
	background: radial-gradient(rem(877) at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
}

.article-main {

	.article-head {
		min-height: rem(430);

		@media (max-width: 767px) {
			min-height: rem(215);
			margin-bottom: rem(25);
		}
	}

	&__row {
		display: flex;

		@media (max-width: 767px) {
			flex-flow: column-reverse;
		}
	}

	&__col {
		min-height: rem(500);
		width: 100%;
		padding-top: rem(70);
		padding-bottom: rem(50);

		@media (max-width: 767px) {
			padding: 0;
		}
	}

	.aside-col {
		max-width: rem(390);
		width: 100%;
		flex-shrink: 0;
		margin-right: rem(55);

		@media (max-width: 767px) {
			margin-right: 0;
			max-width: 100%;
		}
	}

	.article-col {
	}

	&__head {
		margin-bottom: rem(70);

		@media (max-width: 767px) {
			margin-bottom: rem(30);
		}
	}

	&__title {
		margin-bottom: rem(11);
		text-transform: none;

		@media (max-width: 767px) {
			font-size: rem(24);
			line-height: rem(31);
		}
	}

	&__info {
		font-style: italic;
		font-weight: 600;
		font-size: rem(14);
		line-height: rem(18);
		color: #0C284E;
	}

	&__p {
		margin-bottom: rem(50);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}
}

sup {
	position: relative;
	top: rem(-5);
	color: inherit;

	a {
		color: inherit;
		font-size: em(10);
	}
}

.article-desc {

	&.increased-indent {
		padding: rem(50) 0;

		@media (max-width: 767px) {
			padding: rem(20) 0;
		}
	}

	&__subscription {
		margin: 0 auto;
		padding: rem(60) 0;
		max-width: rem(705);
		text-align: center;
		font-size: rem(21);
		line-height: rem(34);
		text-align: center;
		color: #0C284E;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			padding: rem(30) 0;
		}
	}
}

.article-card-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: rem(50) rem(-20);
	margin-bottom: 0;

	@media (max-width: 767px) {
		margin: 0;
		flex-flow: column;
		align-items: center;
	}
}

.article-card {
	max-width: rem(600);
	width: 100%;
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
	border-radius: rem(50);
	position: relative;
	margin: rem(30) rem(20) rem(90) rem(20);

	@media (max-width: 767px) {
		margin: 0;
		margin-bottom: rem(30);
		border-radius: rem(20);
		box-shadow: rem(0) rem(4) rem(20) rgba(188, 213, 247, 0.69);
	}

	&__content {
		position: relative;
		z-index: 2;
		padding: rem(50) rem(60);
		padding-bottom: rem(50);
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		height: 100%;

		@media (max-width: 767px) {
			padding: rem(30);
		}
	}

	&__title {
		margin-top: rem(10);
		font-weight: 900;
		font-size: rem(26);
		line-height: rem(40);
		color: #153E75;
		max-width: rem(308);
		max-height: rem(80);
		overflow: hidden;
		margin-bottom: rem(20);

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
			height: auto;
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;
		margin-bottom: rem(35);
		max-width: rem(380);
		height: rem(102);
		overflow: hidden;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			height: auto;
			max-width: 90%;
		}
	}

	&__img {
		position: absolute;
		right: rem(40);
		bottom: rem(45);

		@media (max-width: 480px) {
			right: rem(10);
			bottom: 40%;
		}
	
	}

	&__stomach-img {
		width: rem(155);

		@media (max-width: 767px) {
			width: rem(94);
		}
	}

	&__woman-img {
		width: rem(168);

		@media (max-width: 767px) {
			width: rem(94);
		}
	}

	&__knife-fork {
		width: rem(169);
		bottom: rem(20);

		@media (max-width: 767px) {
			width: rem(94);
		}
	}

	&__medicines-img {
		width: rem(126);

		@media (max-width: 767px) {
			width: rem(90);
		}
	}
}

.article-video {
	position: relative;
	margin-bottom: rem(60);
	min-height: 1px;

	@media (max-width: 480px) {
		margin-bottom: rem(30);
	}

	.container-2 {
		position: relative;
	}

	&__poster {
		margin: 0 auto;

		img {
			width: 100%;
			max-width: rem(934);
			margin: 0 auto; 
		}
	}
}

.robot-video {
	position: absolute;
	left: rem(190);
	bottom: rem(-50);
	display: flex;
	align-items: flex-end;

	@media (max-width: 767px) {
		position: relative;
		left: auto;
		bottom: auto;
		margin-top: rem(-120);
	}

	
	&__robot-img-7 {
		max-width: rem(169);

		@media (max-width: 767px) {
			max-width: rem(85);
		}
	}
}

.robot-video-say {
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
	border-radius: rem(100);
	display: flex;
	height: rem(110);
	padding-left: rem(105);
	padding-right: rem(20);
	position: relative;
	margin-bottom: rem(110);
	margin-left: rem(40);

	@media (max-width: 767px) {
		padding-left: rem(20);
		border-radius: rem(30);
		align-items: center;
		margin: 0;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: rem(-10);
		justify-content: space-between;
	}

	&:before {
		content: "";
		display: block;
		border: rem(34) solid transparent;
		border-top: rem(31) solid #fff;
		position: absolute;
		left: rem(-29);
		top: rem(22);
		transform: rotate(8deg);

		@media (max-width: 767px) {
			left: rem(20);
			top: rem(-10);
			transform: rotate(38deg);
		}
	}

	&__desc {
		font-weight: 450;
		font-size: rem(14);
		line-height: rem(18);
		color: #A7BFD2;
		margin-top: rem(15);
		margin-bottom: rem(7);

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__title {
		font-style: italic;
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(23);
		color: #0C284E;
		margin-right: rem(70);

		@media (max-width: 767px) {
			margin-right: rem(20);
			font-size: rem(14);
			line-height: rem(21);
		}
	}

	.play {
		margin-top: rem(20);

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}
}

.play {
	display: block;
	width: rem(70);
	height: rem(70);
	background: #215AA7;
	border-radius: 50%;
	position: relative;
	flex-shrink: 0;

	@media (max-width: 767px) {
		width: rem(42);
		height: rem(42);
	}

	&:after {
		content: "";
		position: absolute;
		display: block;
		border: rem(10) solid transparent;	
		border-left: rem(15) solid #fff;
		top: 50%;
		left: 60%;
		transform: translate(-50%, -50%);

		@media (max-width: 767px) {
			border: rem(7) solid transparent;	
			border-left: rem(10) solid #fff;
			top: 50%;
			left: 60%;
		}
	}
}

.reasons {
	background: radial-gradient(rem(750) at 63.29% 29.71%, #F7FBFF 0%, #F8FCFF 46.85%, #F7FBFF 66.21%, rgba(255, 255, 255, 0) 100%), #D8EEFE;
	
	.container-2 {
		background: url("../img/images/man-bg.svg") no-repeat 50% 0 / rem(1562);
		padding-top: rem(97);
		padding-bottom: rem(90);
		height: rem(1060);
		position: relative;

		@media (max-width: 767px) {
			background: url("../img/images/man-bg-mob.svg") no-repeat 50% 0 / rem(551);
			height: auto;
			padding-top: rem(50);
			padding-bottom: rem(50);
		}
	}

	&__title {
		
		max-width: rem(500);
		text-align: center;
		margin: 0 auto;
		margin-bottom: rem(30);

		@media (max-width: 480px) {
			font-size: rem(25);
			line-height: rem(29);
			margin-bottom: rem(10);
			
		}
	}

	&__desc {
		max-width: rem(500);
		font-size: rem(18);
		line-height: rem(32);
		color: #0C284E;
		text-align: center;
		margin: 0 auto;

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(21);
			
		}
	}
}

.stomach-reasons {
	position: absolute;
	bottom: 0;
	left: 51%;
	transform: translate(-50%, 0);

	@media (max-width: 767px) {
		position: relative;
		top: auto;
		left: auto;
		transform: translate(0);
	}

	&__stomach-img-wrap {
		position: relative;
		width: rem(583);
		min-height: 1px;

		@media (max-width: 767px) {
			max-width: rem(250);
			width: 100%;
			margin: 0 auto;
			margin-top: rem(-50);
		}
	}

	&__stomach-img {
		width: 100%;
	}

	.stomach-explanation {
		position: absolute;
		
		@media (max-width: 767px) {
			width: 100% !important;
			position: static !important;
			margin-bottom: rem(20);
		}
	}

	
}

.stomach-explanation {
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
	border-radius: rem(100);
	padding: rem(10) rem(60);
	padding-right: rem(30);

	@media (max-width: 767px) {
		position: static !important;
		margin-bottom: rem(25) !important;
		width: 100% !important;
		background: none;
		border-radius: 0;
		box-shadow: none;
		padding: 0 !important;

		br {
			display: none;
		}
	}

	&:after {
		@media (max-width: 767px) {
			display: none !important;
		}
	}
	
	&:after {
		content: "";
		position: absolute;
		display: block;
	}

	&__title {
		font-size: rem(24);
		line-height: rem(30);
		color: #153E75;
		margin: rem(7) 0;
		font-weight: 900;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	&__desc {
		font-style: italic;
		font-weight: 600;
		font-size: rem(18);
		line-height: rem(23);
		color: #0C284E;
		margin-bottom: rem(15);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&--1 {
		top: rem(192);
    left: -35%;
		width: rem(390);

		&:after {
			border: rem(33) solid transparent;
	    border-bottom: rem(33) solid #fff;
	    top: rem(35);
	    right: rem(-15);
	    transform: rotate(217deg);
		}
	}

	&--2 {
		top: 36%;
    right: -19%;
		width: rem(400);

		&:after {
			border: rem(33) solid transparent;
	    border-top: rem(33) solid #fff;
	    top: rem(51);
	    left: rem(-15);
	    transform: rotate(-42deg);
		}
	}

	&--3 {
		top: 46%;
    left: -45%;
		width: rem(300);

		&:after {
			border: rem(44) solid transparent;
	    border-bottom: rem(33) solid #fff;
	    top: rem(34);
	    right: rem(-41);
	    transform: rotate(164deg);
		}
	}

	&--4 {
		top: 67%;
    left: -15%;
		width: rem(388);

		&:after {
			border: rem(33) solid transparent;
			border-bottom: rem(33) solid #fff;
			top: rem(30);
			right: rem(-30);
			transform: rotate(175deg);
		}
	}

	&--5 {
		bottom: 7%;
    right: -63%;
		width: rem(440);
		padding: rem(30) rem(60);

		&:after {
			border: rem(38) solid transparent;
	    border-top: rem(33) solid #fff;
	    top: rem(35);
	    left: rem(-30);
	    transform: rotate(13deg);
		}
	}
}

.stomach-num {
	width: rem(35);
	height: rem(35);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: rem(14);
	line-height: rem(18);
	text-align: center;
	text-transform: uppercase;
	color: #215AA7;
	box-shadow: rem(0) rem(4) rem(21) rgba(149, 182, 226, 0.57);
	background: #fff;
	position: absolute;

	&--1 {
		top: 29%;
    left: 36%;

	}

	&--2 {
		top: 45%;
		left: 8%;
	}

	&--3 {
		top: 53%;
		left: 80%;
	}

	&--4 {
		top: 79%;
		left: 25%;
	}

	&--5 {
		top: 82%;
		left: 67%;
	}
}	

.myths {
	padding-top: rem(115);
	padding-bottom: rem(50);

	@media (max-width: 767px) {
		padding-top: rem(50);
	}

	&__row {
		display: flex;
		justify-content: space-between;

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__col {
		width: 100%;
		display: flex;
		flex-flow: column;

		&:nth-child(1) {
			width: rem(400);
			padding-right: rem(50);
			flex-shrink: 0;
			min-height: rem(1);
			justify-content: space-between;

			@media (max-width: 767px) {
				padding-right: 0;
				width: auto;
			}
		}
	}

	&__robot-img {
		width: rem(344);
		margin-top: rem(50);
		margin-bottom: rem(50);

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__title {

		@media (max-width: 767px) {
			margin-bottom: rem(30);
			text-align: center;
		}
	}
}

.myth {
	margin-bottom: rem(20);
	margin-top: rem(20);

	@media (max-width: 767px) {
		margin-top: 0;
	}

	&__row {
		display: flex;

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__ico {
		flex-shrink: 0;
		width: rem(100);
		height: rem(100);
		border-radius: 50%;
		margin-right: rem(50);
		background: #215AA7;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 767px) {
			width: rem(75);
			height: rem(75);
			margin-right: 0;
			margin-bottom: rem(30);
		}
	}

	&__img-fire {
		width: rem(68);	
	}

	&__img-drugs {
		width: rem(59);	
	}
	
	&__img-powder {
		width: rem(67);	
	}

	&__ico-img {

		@media (max-width: 767px) {
			width: 60%;
		}
	}

	&__title {
		font-size: rem(24);
		line-height: rem(30);
		font-weight: 900;
		color: #153E75;
		margin-bottom: rem(40);

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
			margin-bottom: rem(20);
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;
		margin-bottom: rem(35);

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
			margin-bottom: rem(20);
		}
	}
}

.after-article {
	position: relative;
	padding-bottom: rem(100);
	padding-top: rem(80);

	@media (max-width: 767px) {
		padding-bottom: rem(50);
		padding-top: rem(40);
	}

	&__cover {
		background: #F6FBFF;
		border-radius: rem(50);
		padding: rem(50) rem(90);
		position: relative;

		@media (max-width: 767px) {
			padding: rem(30);
			border-radius: rem(20);
			padding-bottom: rem(50);
		}
	}

	&__info {
		font-weight: 900;
		font-size: rem(14);
		line-height: rem(18);
		color: #95B6E2;
		margin-bottom: rem(16);
	}

	&__title {
		font-weight: bold;
		font-size: rem(36);
		line-height: rem(46);
		text-transform: uppercase;
		color: #215AA7;
		margin-bottom: rem(37);
		max-width: 60%;

		@media (max-width: 767px) {
			font-size: rem(24);
			line-height: rem(30);
			max-width: 80%;

			br {
				display: none;
			}
		}

		@media (max-width: 480px) {
			max-width: 100%;
		}
	}

	&__img {

		
	}

	&__img-wrap {
		position: absolute;
		bottom: 0;
		overflow: hidden;

		
	}

	&__robot-2-img-wrap {
		right: rem(104);
		height: rem(370);

		@media (max-width: 767px) {
			right: rem(-20) !important;
			height: rem(180) !important;
		}
	}

	&__robot-2-img {
		width: rem(367);

		@media (max-width: 767px) {
			width: rem(183) !important;
		}
	}

	&__robot-3-img-wrap {
		right: rem(183);
		height: rem(380);

		@media (max-width: 767px) {
			right: rem(20) !important;
			height: rem(173) !important;
		}
	}

	&__robot-3-img {
		width: rem(238);

		@media (max-width: 767px) {
			width: rem(124) !important;
		}
	}

	&__robot-4-img-wrap {
		right: rem(183);
		height: rem(350);

		@media (max-width: 767px) {
			right: rem(10) !important;
			height: rem(180) !important;
		}
	}

	&__robot-4-img {
		width: rem(285);

		@media (max-width: 767px) {
			width: rem(152) !important;
		}
	}

	&__robot-5-img-wrap {
		right: rem(65);
		height: rem(395);
		padding-right: rem(70);

		@media (max-width: 767px) {
			width: rem(180);
			height: rem(130)!important;
			padding-right: rem(50);
			right: rem(-40) !important;
		}
	}

	&__robot-5-img {
		width: rem(378);
	}

	&__heart-img {
		position: absolute;
		right: rem(0);
		top: rem(20);
		width: rem(147);

		@media (max-width: 767px) {
			width: rem(50);
		}
	}

	&__link {

		@media (max-width: 767px) {
			padding: 0 rem(32) !important;
			font-size: rem(16) !important;
		}
	}
}

.btn-read {
	max-width: rem(151);
	padding: 0 rem(50);
	height: rem(60);
}

.signs {
	padding-top: rem(30);
	padding-bottom: rem(50);
	margin-top: rem(180);

	@media (max-width: 767px) {
		padding-top: rem(0);
		margin-top: 0;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		margin: 0 rem(-20);
		align-items: flex-end;
		position: relative;
		z-index: 2;

		@media (max-width: 767px) {
			flex-flow: column;
			align-items: center;
			margin: 0;
		}
	}

	.sign {
		margin: rem(20);
		width: 100%;
		max-width: rem(600);
		align-self: stretch;
		position: relative;
		z-index: 1;

		&::before{
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: rem(20);
			height: rem(100);
			background-color: #fff;
			z-index: 3;
		}

		@media (max-width: 767px) {
			max-width: 100%;
			margin: rem(20) 0;
			padding-bottom: rem(70);
		}

		&:first-of-type {
			@media (max-width: 767px) {
				margin-bottom: rem(120);
			}
		}
	}

	.container-2 {
		position: relative;
	}

	&__robot-img {
		position: absolute;
		// right: 42%;
		right: 33%;
		// top: rem(-180);
		top: rem(-158);
		// width: rem(234);
		width: rem(378);
		z-index: 2;
		pointer-events: none;
		display: none;

		@media (max-width: 767px) {
			display: block;
			left: rem(20);
			top: 32%;
			width: rem(218);
		}

		@media (max-width: 480px) {
			display: block;
			left: rem(20);
			top: 36%;
			width: rem(202);
		}
	}

	&__desc {
		max-width: rem(833);
		margin: rem(40) auto;
		margin-top: rem(80);
		font-weight: 600;
		font-size: rem(21);
		line-height: rem(34);
		text-align: center;
		color: #215AA7;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
			margin: rem(20) auto;
		}
	}
}

.sign {
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
	border-radius: rem(50);
	padding: rem(60) rem(80);

	.signs__robot-img {
		right: 70.3%;
		top: rem(-177);
		display: block;

		@media (max-width: 767px) {
			display: none;
		}
	}

	@media (max-width: 767px) {
		box-shadow: rem(0) rem(4) rem(20) rgba(188, 213, 247, 0.69);
		border-radius: rem(20);
		padding: rem(30);
	}

	&:last-child {
		padding-right: rem(40);
		z-index: 3;
	}

	&__head {
		display: flex;
		align-items: center;
		margin-bottom: rem(20);

		@media (max-width: 767px) {
			flex-flow: column;
			align-items: flex-start;
		}
	}

	&__ico {
		border-radius: 50%;
		width: rem(100);
		height: rem(100);
		display: flex;
		align-items: center;
		justify-content: center;
		background: #F7FBFF;
		margin-right: rem(45);
		flex-shrink: 0;

		@media (max-width: 767px) {
			width: rem(50);
			height: rem(50);
			background: none;
			margin-right: 0;
			margin-bottom: rem(15);
		}
	}

	&__ico-img-heart {
		width: rem(75);

		@media (max-width: 767px) {
			width: rem(63)
		}
	}

	&__ico-img-stomach {
		width: rem(61);

		@media (max-width: 767px) {
			width: rem(52);
		}
	}

	&__title {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(30);
		color: #153E75;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(22);
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;
		margin-bottom: rem(35);
		max-width: 100%;

		@media (max-width: 480px) {
			font-size: rem(14);
			line-height: rem(18);
			margin-bottom: rem(20);
		}
	}

	&__list {
		list-style: none;

		li {
			font-size: rem(21);
			line-height: rem(34);

			@media (max-width: 767px) {
				font-size: rem(14);
				line-height: rem(18);
			}

			b {
				@media (max-width: 767px) {
					font-weight: normal;
				}
			}
		}
	}
}

.tell-you-wrap-mgt {
	margin-top: rem(250);

	@media (max-width: 767px) {
		margin-top: rem(50);
	}
}

.causes {
	padding-top: rem(360);
	padding-bottom: rem(309);
	width: 100%;
	position: relative;

	@media (max-width: 767px) {
		padding-top: rem(180);
		padding-bottom: rem(40);
	}

	&__robot {
		width: rem(312);
		margin: 0 auto;
		position: relative;
		z-index: 2;
		right: rem(50);

		@media (max-width: 767px) {
			position: absolute;
			width: rem(202);
			right: 17%;
			top: rem(-50);
			z-index: 1;
		}
	}
}

.cause {
	position: absolute;
	background: #FFFFFF;
	box-shadow: rem(0) rem(4) rem(43) rgba(188, 213, 247, 0.69);
	border-radius: 50%;
	padding: rem(45);

	@media (max-width: 767px) {
		position: relative !important;
		right: auto !important;
		top: auto !important;
		bottom: auto !important;
		left: auto !important;
		border-radius: rem(20);
		width: auto !important;
		height: auto !important;
		padding: rem(30) rem(20) !important;
		margin-bottom: rem(25);
	}

	@media (max-width: 767px) {
		z-index: 2;
	}

	&__ico {
		margin: 0 auto;
		margin-bottom: rem(18);
		width: rem(55);
		margin-top: rem(25);
	}

	&__title {
		font-weight: 900;
		margin: 0 auto;
		margin-bottom: rem(25);
		font-size: rem(24);
		line-height: rem(40);
		text-align: center;
		color: #153E75;
		text-align: center;
		max-width: rem(320);

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
			margin-bottom: rem(15);
		}
	}

	&__desc {
		margin: rem(20) auto;
		font-size: rem(21);
		line-height: rem(34);
		text-align: center;
		color: #0C284E;
		max-width: rem(360);

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}

	&.cause-1 {
		width: rem(473);
		height: rem(473);
		z-index: 1;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);

		@media (max-width: 767px) {
			transform: translate(0) !important;
		}
	}

	&.cause-2 {
		width: rem(403);
		height: rem(403);
		top: rem(217);
		left: 0;
	}

	&.cause-3 {
		width: rem(403);
		height: rem(403);
		top: rem(217);
		right: 0;
	}

	&.cause-4 {
		width: rem(473);
		height: rem(473);
		bottom: rem(116);
		left: rem(98);
		z-index: 2;
		padding: rem(45) rem(60);
	}

	&.cause-5 {
		width: rem(473);
		height: rem(473);
		bottom: rem(116);
		right: rem(98);
		z-index: 2;
	}
}

.tip {
	margin-top: rem(50);
	margin-bottom: rem(135);
	position: relative;

	@media (max-width: 767px) {
		margin-bottom: rem(50);
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__row {
		display: flex;
		position: relative;
		z-index: 2;

		@media (max-width: 767px) {
			flex-flow: column;
		}
	}

	&__col {
		display: flex;
		flex-flow: column;

		&:first-child {
			padding-right: rem(50);
			width: rem(450);
			flex-shrink: 0;

			@media (max-width: 767px) {
				width: 100%;
				padding-right: 0;
				flex-flow: column-reverse;
			}
		}
	}

	&__title {
		margin-bottom: rem(40);

		@media (max-width: 767px) {
			font-size: rem(33);
			line-height: rem(42);
			text-align: center;
			margin-bottom: rem(50);
			margin-top: rem(20);
		}
	}

	&__robot-img {
		margin-top: rem(106);

		@media (max-width: 767px) {
			position: relative;
			margin: 0 auto;
		}
	}

	&__robot-4-img {
		width: rem(244);

		@media (max-width: 767px) {
			width: rem(120);
		}
	}

	&__robot-9-img {
		width: rem(203);

		@media (max-width: 767px) {
			width: rem(100);
		}
	}

	&__robot-10-img {
		width: rem(269);

		@media (max-width: 767px) {
			width: rem(120);
		}
	}

	&__img-bottom {
		width: rem(270);
		position: absolute;
		bottom: rem(-30);
		right: 0;

		@media (max-width: 767px) {
			width: rem(130);
			bottom: rem(30);
		}
	}
}

.tip-list {
	list-style: none;

	li {
		background: url("../img/icons/check-ico.svg") no-repeat 0 rem(5) / rem(29);
		margin-bottom: rem(40);
		padding-left: rem(60);
		max-width: rem(740);

		@media (max-width: 767px) {
			margin-bottom: rem(25);
			padding-left: rem(50);
			background-size: rem(18);
			background-position: 0 rem(6);
		}
	}

	&__title {
		font-weight: 900;
		font-size: rem(24);
		line-height: rem(40);
		color: #153E75;
		margin-bottom: rem(5);

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(23);
		}
	}

	&__desc {
		font-size: rem(21);
		line-height: rem(34);
		color: #0C284E;

		@media (max-width: 767px) {
			font-size: rem(14);
			line-height: rem(18);
		}
	}
}

.widget {
	margin-top: rem(20);
	margin-bottom: rem(70);
	padding: rem(35);
	background: #F6FBFF;
	display: flex;
	flex-flow: column;
	max-width: rem(390);

	@media (max-width: 767px) {
		padding: rem(20);
		margin-left: auto;
		margin-right: auto;
	}

	&__img {
		width: 100%;
	}

	&__title {
		font-size: rem(24);
		line-height: rem(31);
		text-align: center;
		color: #215AA7;
		margin: rem(40) 0;

		@media (max-width: 767px) {
			font-size: rem(18);
			line-height: rem(21);
		}
	}

	.btn {
		align-self: center;
		margin-bottom: rem(20);
	}

	&__robot-img {
		position: relative;
		margin: 0 auto;
		width: rem(160);
		right: rem(27);
		position: relative;
		margin-top: rem(-70);

		@media (max-width: 767px) {
			width: rem(120);
			right: rem(15);
		}
	}
}

.breadcrumbs {
	padding: rem(44) rem(65); 
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: 767px) {
		padding: rem(26);
	}

	&__link {
		display: block;
		line-height: rem(36);
		margin-right: rem(20);
		background-color: #FFFFFF;
		padding: 0 rem(16);
		border-radius: rem(20);
		color: #215AA7;
		font-weight: 600;
		font-size: rem(18);
		transition: all .2s;
		max-width: rem(246);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: rem(350);

		@media (max-width: 767px) {
			line-height: rem(20);
			font-size: rem(10);
			padding: 0 rem(13);
			margin-right: rem(18);
			margin-bottom: rem(10);
		}

		@media (max-width: 480px) {
			display: none;
		}

		&:nth-child(1),
		&:nth-child(2) {
			display: inline-flex;
		}

		svg {
			path, rect {
				transition: all .3s;
			}
		}

		&:hover {
			text-decoration: none;
			background: #215AA7;
			color: #fff;

			svg {
				path, rect {
					fill: #fff;
				}
			}
		}
	}

	&__home {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(36);
		height: rem(36);
		padding: 0;
		border-radius: 50%;
		flex-shrink: 0;
		
		@media (max-width: 767px) {
			width: rem(20);
			height: rem(20);
		}

		svg {
			width: rem(16);
			margin-bottom: rem(3);

			@media (max-width: 767px) {
				width: rem(8);
				margin-bottom: 0;
			}
		}
	}
}