@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-Book.eot');
	src: url('../fonts/FuturaPT/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-Book.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-Medium.eot');
	src: url('../fonts/FuturaPT/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-Demi.eot');
	src: url('../fonts/FuturaPT/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-Demi.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-Demi.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-DemiObl.eot');
	src: url('../fonts/FuturaPT/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-DemiObl.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-DemiObl.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-Bold.eot');
	src: url('../fonts/FuturaPT/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-Bold.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('FuturaPT-BoldObl.eot');
	src: url('../fonts/FuturaPT/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-BoldObl.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-BoldObl.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT/FuturaPT-Heavy.eot');
	src: url('../fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPT-Heavy.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPT-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT Cond Book';
	src: url('../fonts/FuturaPT/FuturaPTCond-Book.eot');
	src: url('../fonts/FuturaPT/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT/FuturaPTCond-Book.woff') format('woff'),
		url('../fonts/FuturaPT/FuturaPTCond-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.eot');
	src: url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.woff') format('woff'),
		url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.eot');
	src: url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.woff') format('woff'),
		url('../fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}